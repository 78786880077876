import React, { Component } from 'react'
import update from "immutability-helper"
import classNames from "classnames"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import { isUndefined, access_token, remove_login_data, get_instansi_selected, user_id } from '../../../config/config'
import { API } from '../../../config/api'
import { toast } from 'react-toastify'
import withAuth from "../../../component/hoc/auth"
import JabatanContext from '../../../store/jabatan_context'
import OrganizationChart from '../../../component/ui/org_chart'
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import { CustomRadioPicker } from '../../../component/ui/custom_input'
import { FiCheck } from 'react-icons/fi'
import { ConfirmDelete } from '../../../component/ui/confirm'
import { messaging, vapid_key } from '../../../config/firebase'
import RouterContext from '../../../store/router_context'
import { getToken, onMessage, getMessaging } from "firebase/messaging"
import { pusher } from '../../../config/pusher'

class Jabatan extends Component {
    state={
        instansi:"",
        jabatan:[],
        tambah_jabatan:{
            is_open:false,
            id_instansi:"",
            bawahan_dari:"",
            nama_jabatan:"",
            jabatan_type:"atasan"
        },
        form_validation_tambah_jabatan:{},
        edit_jabatan:{
            is_open:false,
            id_jabatan:"",
            nama_jabatan:""
        },
        form_validation_edit_jabatan:{},
        hapus_jabatan:{
            is_open:false,
            id_jabatan:""
        },
        tambah_pekerja:{
            is_open:false,
            id_jabatan:"",
            email:"",
        },
        hapus_pekerja:{
            is_open:false,
            id_jabatan_user:""
        },
        form_validation_tambah_pekerja:{},
        is_loading:false
    }

    componentDidMount=()=>{
        this.getSelectedInstansi()
        
        const context=this.context

        if(get_instansi_selected().id_instansi!==""){
            const private_channel=pusher(access_token()).subscribe("private-instansi_"+get_instansi_selected().id_instansi)
            private_channel.bind("notif", data=>{
                this.getSelectedInstansi()
            })
        }
    }
    //get data
    getSelectedInstansi=()=>{
        if(get_instansi_selected().id_instansi!==""){
            this.setState({
                instansi:get_instansi_selected()
            }, ()=>{
                this.getOrganization()
            })
        }
    }
    getOrganization=()=>{
        API.post("/dashboard/jabatan/get_organization", JSON.stringify({
            token:access_token(),
            id_instansi:this.state.instansi.id_instansi
        }))
        .then(res=>{
            this.setState({
                jabatan:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }
    getSearchPekerja=q=>{
        API.post("/dashboard/users/search_pekerja", JSON.stringify({
            token:access_token(),
            search:q
        }))
        .then(res=>{
            this.setState({
                tambah_pekerja:update(this.state.tambah_pekerja, {
                    results:{$set:res.data.data},
                    is_typing:{$set:false}
                })
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }

     //tambah jabatan
     showTambahJabatan=id_jabatan=>{
        this.setState({
            tambah_jabatan:{
                is_open:true,
                id_instansi:this.state.instansi.id_instansi,
                bawahan_dari:id_jabatan,
                nama_jabatan:"",
                jabatan_type:"atasan"
            }
        })
    }
    hideTambahJabatan=()=>{
        this.setState({
            tambah_jabatan:{
                is_open:false,
                id_instansi:"",
                bawahan_dari:"",
                nama_jabatan:"",
                jabatan_type:"atasan"
            }
        })
    }
    typeJabatan=e=>{
        const target=e.target

        this.setState({
            tambah_jabatan:update(this.state.tambah_jabatan, {
                [target.name]:{$set:target.value}
            })
        })
    }
    getRoleChecked=val=>{
        if(val===this.state.tambah_jabatan.jabatan_type){
            return true
        }
        else{
            return false
        }
    }
    tambahJabatan=e=>{
        e.preventDefault()

        const {instansi, tambah_jabatan}=this.state

        this.setState({is_loading:true})
        API.post("/dashboard/jabatan/add_jabatan", JSON.stringify({
            token:access_token(),
            id_instansi:instansi.id_instansi,
            nama_jabatan:tambah_jabatan.nama_jabatan,
            bawahan_dari:tambah_jabatan.bawahan_dari,
            jabatan_type:tambah_jabatan.jabatan_type
        }))
        .then(res=>{
            this.getOrganization()
            this.hideTambahJabatan()
            this.setState({is_loading:false})
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
                case 500:
                    this.setState({
                        form_validation_tambah_jabatan:err.response.data.status.data
                    })
                break;
            }
            this.setState({is_loading:false})
        })
    }

    //edit jabatan
    showEditJabatan=data=>{
        this.setState({
            edit_jabatan:{
                is_open:true,
                id_jabatan:data.id_jabatan,
                nama_jabatan:data.nama_jabatan
            }
        })
    }
    hideEditJabatan=()=>{
        this.setState({
            edit_jabatan:{
                is_open:false,
                id_jabatan:"",
                nama_jabatan:""
            }
        })
    }
    typeEditJabatan=(e)=>{
        const target=e.target

        this.setState({
            edit_jabatan:update(this.state.edit_jabatan, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updateJabatan=e=>{
        e.preventDefault()

        const {edit_jabatan}=this.state

        this.setState({is_loading:true})
        API.post("/dashboard/jabatan/update_jabatan", JSON.stringify({
            token:access_token(),
            id_jabatan:edit_jabatan.id_jabatan,
            nama_jabatan:edit_jabatan.nama_jabatan
        }))
        .then(res=>{
            this.getOrganization()
            this.hideEditJabatan()
            this.setState({is_loading:false})
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
                case 500:
                    this.setState({
                        form_validation_edit_jabatan:err.response.data.status.data
                    })
                break;
            }
            this.setState({is_loading:false})
        })
    }

    //hapus jabatan
    showHapusJabatan=id_jabatan=>{
        this.setState({
            hapus_jabatan:{
                is_open:true,
                id_jabatan:id_jabatan
            }
        })
    }
    hideHapusJabatan=()=>{
        this.setState({
            hapus_jabatan:{
                is_open:false,
                id_jabatan:""
            }
        })
    }
    hapusJabatan=e=>{
        e.preventDefault()
        API.post("/dashboard/jabatan/delete_jabatan", JSON.stringify({
            token:access_token(),
            id_jabatan:this.state.hapus_jabatan.id_jabatan
        }))
        .then(res=>{
            this.getOrganization()
            this.hideHapusJabatan()
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }

    //tambah pekerja
    showTambahPekerja=id_jabatan=>{
        this.setState({
            tambah_pekerja:{
                is_open:true,
                id_jabatan:id_jabatan,
                email:""
            }
        })
    }
    hideTambahPekerja=()=>{
        this.setState({
            tambah_pekerja:{
                is_open:false,
                id_jabatan:"",
                email:""
            }
        })
    }
    typePekerja=e=>{
        const target=e.target

        this.setState({
            tambah_pekerja:update(this.state.tambah_pekerja, {
                [target.name]:{$set:target.value}
            })
        })
    }
    tambahPekerja=e=>{
        e.preventDefault()

        const {tambah_pekerja}=this.state

        this.setState({is_loading:true})
        API.post("/dashboard/jabatan_users/add_jabatan_user", JSON.stringify({
            token:access_token(),
            id_jabatan:tambah_pekerja.id_jabatan,
            email:tambah_pekerja.email
        }))
        .then(res=>{
            this.getOrganization()
            this.hideTambahPekerja()
            this.setState({is_loading:false})
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
                case 500:
                    this.setState({
                        form_validation_tambah_pekerja:err.response.data.status.data
                    })
                break;
                case 403:
                    toast.warn("User tidak Ditemukan, Pastikan E-Mail sudah benar dan User yang Terkait sudah daftar!")
                break;
            }
            this.setState({is_loading:false})
        })
    }

    //hapus pekerja
    showHapusPekerja=id_jabatan_user=>{
        this.setState({
            hapus_pekerja:{
                is_open:true,
                id_jabatan_user:id_jabatan_user
            }
        })
    }
    hideHapusPekerja=()=>{
        this.setState({
            hapus_pekerja:{
                is_open:false,
                id_jabatan_user:""
            }
        })
    }
    hapusPekerja=e=>{
        e.preventDefault()

        API.post("/dashboard/jabatan_users/delete_jabatan_user", JSON.stringify({
            token:access_token(),
            id_jabatan_user:this.state.hapus_pekerja.id_jabatan_user
        }))
        .then(res=>{
            this.getOrganization()
            this.hideHapusPekerja()
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }

    //context
    jabatanContextValue=()=>{
        return {
            showTambahJabatan:this.showTambahJabatan,
            showEditJabatan:this.showEditJabatan,
            showHapusJabatan:this.showHapusJabatan,
            showTambahPekerja:this.showTambahPekerja,
            showHapusPekerja:this.showHapusPekerja
        }
    }

    render() {
        const {jabatan, tambah_jabatan, form_validation_tambah_jabatan, edit_jabatan, form_validation_edit_jabatan, tambah_pekerja, hapus_jabatan, hapus_pekerja, is_loading}=this.state
        
        return (
            <>
                <div className="content mt-4 mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div class="card widget-tambah-tugas">
                                    <div class="card-header d-flex justify-content-between">
                                        <h5 className="card-title text-truncate">Jabatan/Struktur Organisasi</h5>
                                    </div>
                                    <div className="card-body rounded-bottom-0 hide-scrollbar p-3" style={{overflowX:"auto", minHeight:"300px"}}>
                                        <TransformWrapper
                                            initialScale={1}
                                            initialPositionX={200}
                                            initialPositionY={100}
                                            minScale={.5}
                                            maxScale={5}
                                            centerOnInit
                                        >
                                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                                <div className="tools">
                                                    <button className="btn btn-secondary btn-sm btn-square" onClick={() => zoomIn()}>+</button>
                                                    <button className="btn btn-secondary btn-sm btn-square ms-2" onClick={() => zoomOut()}>-</button>
                                                    <button className="btn btn-secondary btn-sm btn-square ms-2" onClick={() => resetTransform()}>x</button>
                                                </div>
                                                <TransformComponent wrapperClass="w-100 h-500">
                                                    <JabatanContext.Provider value={this.jabatanContextValue()}>
                                                        <OrganizationChart
                                                            data={jabatan}
                                                        />
                                                    </JabatanContext.Provider>
                                                </TransformComponent>
                                            </React.Fragment>
                                            )}
                                        </TransformWrapper>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MODAL TAMBAH JABATAN */}
                <Modal show={tambah_jabatan.is_open} backdrop="static" onHide={this.hideTambahJabatan}>
                    <form onSubmit={this.tambahJabatan}>
                        <Modal.Header closeButton>
                            <Modal.Title className="fw-bold fs-5">Tambah Jabatan</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    !isUndefined(form_validation_tambah_jabatan.jabatan_type)?
                                    <Popover id="popover-ddf" className="popover-danger">
                                        <Popover.Body>
                                            <span class="text-white">Tipe Jabatan Harus diisi!</span>
                                        </Popover.Body>
                                    </Popover>
                                    :<></>
                                }
                            >
                                <div className="row g-3">
                                    <div className="col-sm-6">
                                        <CustomRadioPicker
                                            label="Role Atasan"
                                            info="Dapat Menerima dan Mengirim Tugas!"
                                            checked={this.getRoleChecked("atasan")}
                                            name="jabatan_type"
                                            value="atasan"
                                            isInvalid={ !isUndefined(form_validation_tambah_jabatan.jabatan_type)?true:false}
                                            onChange={this.typeJabatan}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <CustomRadioPicker
                                            label="Role Bawahan"
                                            info="Hanya bisa Menerima Tugas!"
                                            checked={this.getRoleChecked("bawahan")}
                                            name="jabatan_type"
                                            value="bawahan"
                                            isInvalid={ !isUndefined(form_validation_tambah_jabatan.jabatan_type)?true:false}
                                            onChange={this.typeJabatan}
                                        />
                                    </div>
                                </div>
                            </OverlayTrigger>
                            <div class="row mt-3">
                                <div className="col-12">
                                    <label for="nama_jabatan" class="form-label fw-semibold">Nama Jabatan</label>
                                    <OverlayTrigger
                                        trigger="focus"
                                        placement="right"
                                        overlay={
                                            !isUndefined(form_validation_tambah_jabatan.nama_jabatan)?
                                            <Popover id="popover-ddf" className="popover-danger">
                                                <Popover.Body>
                                                    <span class="text-white">Nama Jabatan Harus diisi!</span>
                                                </Popover.Body>
                                            </Popover>
                                            :<></>
                                        }
                                    >
                                        <input 
                                            type="text" 
                                            class={classNames("form-control", {"is-invalid":!isUndefined(form_validation_tambah_jabatan.nama_jabatan)})}
                                            id="nama_jabatan"
                                            name="nama_jabatan"
                                            value={tambah_jabatan.nama_jabatan}
                                            onChange={this.typeJabatan}
                                        />
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-between">
                            <button type="button" className="btn btn-light fw-semibold" onClick={this.hideTambahJabatan}>
                                Close
                            </button>
                            <button className="btn btn-primary fw-semibold" type="submit" disabled={is_loading}>
                                <FiCheck className="fs-6 me-1"/>
                                Simpan Data
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL EDIT JABATAN */}
                <Modal show={edit_jabatan.is_open} backdrop="static" onHide={this.hideEditJabatan}>
                    <form onSubmit={this.updateJabatan}>
                        <Modal.Header closeButton>
                            <Modal.Title className="fw-bold fs-5">Edit Jabatan</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row">
                                <div className="col-12">
                                    <label for="edit_nama_jabatan" class="form-label fw-semibold">Nama Jabatan</label>
                                    <OverlayTrigger
                                        trigger="focus"
                                        placement="right"
                                        overlay={
                                            !isUndefined(form_validation_edit_jabatan.nama_jabatan)?
                                            <Popover id="popover-ddf" className="popover-danger">
                                                <Popover.Body>
                                                    <span class="text-white">Nama Jabatan Harus diisi!</span>
                                                </Popover.Body>
                                            </Popover>
                                            :<></>
                                        }
                                    >
                                        <input 
                                            type="text" 
                                            class={classNames("form-control", {"is-invalid":!isUndefined(form_validation_edit_jabatan.nama_jabatan)})}
                                            id="edit_nama_jabatan"
                                            name="nama_jabatan"
                                            value={edit_jabatan.nama_jabatan}
                                            onChange={this.typeEditJabatan}
                                        />
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-between">
                            <button type="button" className="btn btn-light fw-semibold" onClick={this.hideEditJabatan}>
                                Close
                            </button>
                            <button className="btn btn-primary fw-semibold" type="submit" disabled={is_loading}>
                                <FiCheck className="fs-6 me-1"/>
                                Simpan Data
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL HAPUS JABATAN */}
                <ConfirmDelete
                    show={hapus_jabatan.is_open}
                    toggle={this.hideHapusJabatan}
                    title="Yakin ingin Dihapus"
                    sub_title="Data Jabatan yang sudah dihapus tidak bisa dikembalikan lagi!"
                    deleteAction={this.hapusJabatan}
                />

                {/* MODAL TAMBAH PEKERJA */}
                <Modal show={tambah_pekerja.is_open} backdrop="static" onHide={this.hideTambahPekerja} dialogClassName="modal-smd">
                    <form onSubmit={this.tambahPekerja}>
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold fs-5">Tambah Pekerja</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="E-Mail Pengguna"
                                name="email"
                                value={tambah_pekerja.email}
                                onChange={this.typePekerja}
                            />
                        </div>
                        <div className="form-text mt-2">Masukkan Email Pekerja/Karyawan dan Undang ke Jabatan yang Dipilih!</div>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between">
                        <button type="button" className="btn btn-light fw-semibold" onClick={this.hideTambahPekerja}>
                            Close
                        </button>
                        <button className="btn btn-primary fw-semibold" type="submit" disabled={is_loading}>
                            Undang Pekerja
                        </button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL HAPUS PEKERJA */}
                <ConfirmDelete
                    show={hapus_pekerja.is_open}
                    toggle={this.hideHapusPekerja}
                    title="Yakin ingin Dihapus"
                    sub_title="Data Pekerja yang sudah dihapus tidak bisa dikembalikan lagi!"
                    deleteAction={this.hapusPekerja}
                />
            </>
        )
    }
}
Jabatan.contextType=RouterContext

export default withAuth(Jabatan)