import React, { Component } from 'react'
import update from "immutability-helper"
import { Link } from 'react-router-dom'
import withAuth from '../../../component/hoc/auth'
import Avatar from '../../../component/ui/avatar'
import { API } from '../../../config/api'
import { access_token, check_instansi, get_instansi_selected, remove_login_data } from '../../../config/config'
import {FaCalendarAlt, FaSearch} from "react-icons/fa"
import { Modal } from 'react-bootstrap'
import moment from 'moment'

class Member extends Component {
    state={
        member:[],
        search:"",
        activity:{
            is_open:false,
            id_user:"",
            tgl:"",
            user:{},
            absensi:{
                cek_in:"",
                cek_out:""
            },
            data:[]
        },
        profile:{
            is_open:false,
            data:{}
        }
    }

    componentDidMount=()=>{
        this.getListMember()
    }
    getListMember=()=>{
        API.post("/dashboard/instansi/get_list_member", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi
        }))
        .then(res=>{
            this.setState({
                member:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }
    getProfile=user_id=>{
        API.post("/dashboard/users/get_profile", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi,
            id_user:user_id
        }))
        .then(res=>{
            this.setState({
                profile:{
                    is_open:true,
                    data:res.data.data
                }
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }

    //search
    typeSearch=(e)=>{
        const target=e.target

        this.setState({
            search:target.value
        })
    }
    searchedMember=()=>{
        return this.state.member.filter(m=>{
            return m.nama_lengkap.search(this.state.search.replace(/\\/g, "\\\\"))!==-1
        })
    }

    //activity
    showActivity=id_user=>{
        const date=new Date()

        this.setState({
            activity:{
                is_open:false,
                id_user:id_user,
                user:{},
                tgl:date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate(),
                absensi:{
                    cek_in:"",
                    cek_out:""
                },
                data:[]
            }
        }, ()=>{
            this.getMemberActivity()
        })
    }
    hideActivity=()=>{
        this.setState({
            activity:{
                is_open:false,
                id_user:"",
                tgl:"",
                user:{},
                absensi:{
                    cek_in:"",
                    cek_out:""
                },
                data:[]
            }
        })
    }
    typeTglActivity=(e)=>{
        const target=e.target

        this.setState({
            activity:update(this.state.activity, {
                tgl:{$set:target.value}
            })
        }, ()=>{
            this.getMemberActivity()
        })
    }
    getMemberActivity=()=>{
        const {activity}=this.state

        API.post("/dashboard/member/get_member_activity", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi,
            id_user:activity.id_user,
            tgl_activity:activity.tgl
        }))
        .then(res=>{
            this.setState({
                activity:update(this.state.activity, {
                    is_open:{$set:true},
                    user:{$set:res.data.user},
                    absensi:{$set:res.data.absensi},
                    data:{$set:res.data.activity}
                })
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }
    getStatusTugas=status=>{
        switch(status){
            case "progress":
                return <span className="badge bg-azure align-self-start px-3">Sedang Dikerjakan</span>
            break;
            case "problem":
                return <span className="badge bg-red align-self-start px-3">Ada Masalah</span>
            break;
            case "delivery":
                return <span className="badge bg-purple align-self-start px-3">Mohon Dicek</span>
            break;
            case "complete":
                return <span className="badge bg-green align-self-start px-3">Selesai</span>
            break;
            case "continue":
                return <span  className="badge bg-blue align-self-start px-3">Lanjutkan</span>
            break;
        }
    }

    //profile
    hideProfile=()=>{
        this.setState({
            profile:{
                is_open:false,
                data:[]
            }
        })
    }
    getProfileStatus=status=>{
        switch(status){
            case "active":
                return <span className="badge bg-success">Aktif</span>
            break;
            case "unverified":
                return <span className="badge bg-warning">Belum diverifikasi</span>
            break;
        }
    }

    render() {
        const {member, activity, profile}=this.state

        return (
            <>
                <div className="content mt-4 mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div class="card widget-kpi">
                                    <div class="card-header d-flex justify-content-between">
                                        <h5 className="card-title text-truncate">Semua Member</h5>
                                        <div class="input-group input-group-search mb-3" style={{maxWidth:"250px"}}>
                                            <span class="input-group-text"><FaSearch/></span>
                                            <input 
                                                type="text" 
                                                class="form-control" 
                                                placeholder="Cari Nama..."
                                                value={this.state.search}
                                                onChange={this.typeSearch}
                                            />
                                        </div>
                                    </div>
                                    <div class="card-body py-4 mb-3">
                                        <div class="list-group">
                                            {this.searchedMember().map(user=>(
                                                <div class="list-group-item d-flex align-items-center py-2 border-0">
                                                    <span className="avatar avatar-xmd">
                                                        <Avatar data={user} circle/>
                                                    </span>
                                                    <div className="mx-3 d-flex flex-column align-items-start">
                                                        <h4 className="fs-7 fw-bold mb-0 cursor-pointer" onClick={()=>this.getProfile(user.id_user)}>
                                                            <span className="text-dark">{user.nama_lengkap}</span>
                                                        </h4>
                                                        <span className="text-muted fs-8">{user.jabatan}</span>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <button 
                                                            className="btn btn-outline-primary rounded-pill"
                                                            onClick={()=>this.showActivity(user.id_user)}
                                                        >
                                                            Lihat Activity
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* activity */}
                <Modal show={activity.is_open} onHide={this.hideActivity} dialogClassName="modal-lg">
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold fs-5">Activity</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <div className="d-flex align-items-center">
                            <span className="avatar avatar-lg">
                                <Avatar data={activity.user} circle/>
                            </span>
                            <div className="d-flex flex-column ms-2">
                                <span className="fw-semibold">{activity.user.nama_lengkap}</span>
                                <span className="text-muted">{activity.user.jabatan}</span>
                            </div>
                        </div>
                        <div className="d-block bg-white mt-4">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex align-items-center">
                                    <button className="btn btn-link p-0">
                                        <FaCalendarAlt className="fs-4 text-primary"/>
                                    </button>
                                    <span className="ms-3">{moment(activity.tgl).format("dddd, Do MMMM YYYY")}</span>
                                </div>
                                <div>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={activity.tgl}
                                        onChange={this.typeTglActivity}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-6">
                                <div className="bg-success p-4 rounded-3x d-flex flex-column">
                                    <span className="fw-bold fs-5 text-white">Jam Cek In</span>
                                    <span className="mt-1 text-white">
                                        {activity.absensi.jam_cek_in!==""?
                                            <>{activity.absensi.jam_cek_in}</>
                                        :
                                            <>Belum Cek In</>
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="bg-danger p-4 rounded-3x d-flex flex-column">
                                    <span className="fw-bold fs-5 text-white">Jam Cek Out</span>
                                    <span className="mt-1 text-white">
                                        {activity.absensi.jam_cek_out!==""?
                                            <>{activity.absensi.jam_cek_out}</>
                                        :
                                            <>Belum Cek Out</>
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <h4 className="fs-4 fw-bold">Log Activity Progress</h4>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" width="50">#</th>
                                        <th scope="col">Tugas</th>
                                        <th scope="col" width="150">Status</th>
                                        <th scope="col" width="180">Submit At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {activity.data.map((ad, i)=>(
                                        <tr>
                                            <td>{i+1}</td>
                                            <td>{ad.nama_tugas}</td>
                                            <td>{this.getStatusTugas(ad.status_activity)}</td>
                                            <td>{ad.created_at_activity}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                </Modal>
                
                {/* MODAL PROFILE */}
                <Modal show={profile.is_open} onHide={this.hideProfile} dialogClassName="modal-smd modal-dialog-centered">
                    <Modal.Body>
                        <div class="row row-sm align-items-center">
                            <div class="col-auto">
                                <span class="avatar avatar-lg">
                                    <Avatar
                                        data={profile.data}
                                        circle
                                    />
                                </span>
                            </div>
                            <div class="col">
                                <h4 class="card-title m-0 fw-bold">
                                    <span>{profile.data.nama_lengkap}</span>
                                </h4>
                                <div class="text-muted">
                                    {profile.data.jabatan}
                                </div>
                            </div>
                        </div>
                        <dl class="row mt-4">
                            <dt class="col-12 text-muted fw-normal">E-Mail</dt>
                            <dd class="col-12 text-wrap">{profile.data.email}</dd>
                            <dt class="col-12 text-muted fw-normal">Username</dt>
                            <dd class="col-12">{profile.data.username}</dd>
                            <dt class="col-12 text-muted fw-normal">Jabatan</dt>
                            <dd class="col-12">{profile.data.jabatan}</dd>
                            <dt class="col-12 text-muted fw-normal">No Telepon</dt>
                            <dd class="col-12">{profile.data.no_hp}</dd>
                            <dt class="col-12 text-muted fw-normal">Status</dt>
                            <dd class="col-12">{this.getProfileStatus(profile.data.status)}</dd>
                        </dl>
                        <div className="d-grid grip-2">
                            <button className="btn btn-secondary btn-pill mt-3" onClick={this.hideProfile}>Tutup Profile</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default withAuth(Member)