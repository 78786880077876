import React, { lazy, Suspense } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { API } from "./config/api";
import { get_instansi_selected, is_login, login_data } from "./config/config"

//pages
import Authentication from "./pages/auth"
import Dashboard from "./pages/dashboard";


class App extends React.Component{
    state={
        install_prompt:false,
        deferred_prompt:"",
        is_login:false,
        is_loading:true
    }

    componentDidMount=()=>{
        if(is_login()){
            API.post("/authentication/verify_token", JSON.stringify({
                token:login_data().access_token
            }))
            .then(res=>{
                const user_data=login_data()
                if(user_data.status!==res.data.data.status){
                    const new_user_data={
                        ...user_data, 
                        ...{status:res.data.data.status}
                    }
                    localStorage.setItem("user_data", JSON.stringify(new_user_data))
                }
                this.setState({
                    is_loading:false,
                    is_login:true
                })
            })
            .catch(err=>{
                this.setState({
                    is_loading:false,
                    is_login:false
                })
            })
        }
        else{
            this.setState({
                is_loading:false,
                is_login:false
            })
        }
    }

    render(){
        const {is_loading, is_login}=this.state

        return (
            <>
                {!is_loading&&
                    <>
                        {!is_login?
                            <Authentication {...this.props}/>
                        :
                            <Dashboard {...this.props} user={login_data()} instansi_selected={get_instansi_selected()}/>
                        }
                    </>
                }

                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    newestOnTop={false}
                    rtl={false}
                    closeOnClick
                    hideProgressBar
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </>
        )
    }
}

export default withRouter(App)