import React, { Component } from 'react'
import classNames from "classnames"
import { withRouter } from 'react-router-dom'
import { API } from '../../config/api'
import { goto_page, login_data, is_login, access_token, get_instansi_selected } from '../../config/config'
import RouterContext from '../../store/router_context'
import { messaging, vapid_key } from '../../config/firebase'
import { getToken, onMessage, getMessaging } from "firebase/messaging"

export default function withAuth(WrappedComponent, data){
    class authHOC extends React.Component{
        state={
            show_page:false,
            animated:false
        }
        
        componentDidMount=()=>{
            const context=this.context

            context.setActiveMenu(this.props.match.path)
            context.getUnreadNotifikasi()

            if(is_login()){
                this.tokenVerify()
                if(get_instansi_selected().id_instansi==="" && !['/start_wizard', '/blank', '/profile'].includes(this.props.match.path)){
                    this.props.history.push("/blank")
                }
                if(login_data().status==="active"){
                    this.setState({
                        show_page:true
                    })
                    setTimeout(() => {
                        this.setState({
                            animated:true
                        })
                    }, 1);
                }
                if(login_data().status==="unverified"){
                    this.props.history.push("/")
                }
            }
            else{
                goto_page("/")
            }
        }
        componentDidUpdate=(prevProps)=>{
            if(this.props.location.key!==prevProps.location.key){
                this.setState({
                    animated:false
                }, ()=>{
                    setTimeout(() => {
                        this.setState({
                            animated:true
                        })
                    }, 1);
                })
            }
        }
        tokenVerify=()=>{
            API.post("/authentication/verify_token", JSON.stringify({
                token:access_token()
            }))
            .then(res=>{
                const user_data=login_data()
                let new_user_data=user_data
                if(user_data.status!==res.data.data.status){
                    new_user_data={
                        ...user_data, 
                        ...{status:res.data.data.status}
                    }
                    localStorage.setItem("user_data", JSON.stringify(new_user_data))
                }
            })
            .catch(err=>{
                goto_page("/")
            })
        }

        render(){
            return (
                <div style={{overflow:"hidden"}}>
                    {this.state.show_page&&
                        <div className={classNames("tg-zoom-enter", {"tg-zoom-enter-active":this.state.animated})}>
                            <WrappedComponent {...this.props}/>
                        </div>
                    }
                </div>
            )
        }
    }
    authHOC.contextType=RouterContext

    return withRouter(authHOC)
}