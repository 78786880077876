import React, { Component } from 'react'
import classNames from "classnames"
import update from "immutability-helper"
import { FiClock, FiEdit } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import withAuth from '../../../component/hoc/auth'
import { API } from '../../../config/api'
import { access_token, remove_login_data, isUndefined } from '../../../config/config'
import {Modal, OverlayTrigger, Popover} from "react-bootstrap"
import Avatar from '../../../component/ui/avatar'

class DetailProyek extends Component {
    state={
        proyek:{},
        tugas:[],
        edit_proyek:{
            is_open:false,
            nama_proyek:"",
            id_proyek:""
        },
        form_validation_edit_proyek:{},
        is_loading:false
    }

    componentDidMount=()=>{
        this.getProyek()
        this.getListTugasProyek()
    }
    getProyek=()=>{
        API.post("/dashboard/proyek/get_proyek", JSON.stringify({
            token:access_token(),
            id_proyek:this.props.match.params.id_proyek
        }))
        .then(res=>{
            this.setState({
                proyek:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()
                break;
            }
        })
    }
    getListTugasProyek=()=>{
        API.post("/dashboard/tugas/list_tugas_proyek", JSON.stringify({
            token:access_token(),
            id_proyek:this.props.match.params.id_proyek
        }))
        .then(res=>{
            this.setState({
                tugas:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()
                break;
            }
        })
    }

    //edit proyek
    showEditProyek=(data)=>{
        this.setState({
            edit_proyek:update(this.state.edit_proyek, {
                is_open:{$set:true},
                id_proyek:{$set:data.id_proyek},
                nama_proyek:{$set:data.nama_proyek}
            })
        })
    }
    hideEditProyek=()=>{
        this.setState({
            edit_proyek:update(this.state.edit_proyek, {
                is_open:{$set:false},
                id_proyek:{$set:""},
                nama_proyek:{$set:""}
            })
        })
    }
    typeEditProyek=(e)=>{
        const target=e.target

        this.setState({
            edit_proyek:update(this.state.edit_proyek, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updateProyek=(e)=>{
        e.preventDefault()

        this.setState({is_loading:true})
        API.post("/dashboard/proyek/update_proyek", JSON.stringify({
            token:access_token(),
            id_proyek:this.state.edit_proyek.id_proyek,
            nama_proyek:this.state.edit_proyek.nama_proyek
        }))
        .then(res=>{
            this.setState({
                is_loading:false
            })
            this.hideEditProyek()
            this.getProyek()
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
                case 500:
                    this.setState({
                        form_validation_edit_proyek:err.response.data.status.data
                    })
                break;
            }
            this.setState({is_loading:false})
        })
    }

    
    render() {
        const {proyek, tugas, edit_proyek, form_validation_edit_proyek, is_loading}=this.state

        return (
            <>
                <div className="content mt-4 mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div class="card widget-list-tugas">
                                            <div class="card-header d-flex flex-column flex-md-row justify-content-between">
                                                <h5 className="card-title text-truncate d-none d-md-inline-block">Detail Proyek</h5>
                                            </div>
                                            <div class="card-body p-4 mb-4">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <img src="/img/project.png" className="img-project"/>
                                                    </div>
                                                    <div class="flex-grow-1 ms-4">
                                                        <h2 className="fs-5 fw-bold mb-3">
                                                            <span className="text-dark fw-bold">{proyek.nama_proyek}</span>
                                                        </h2>
                                                        <div className="d-flex align-items-center">
                                                            <span className="text-muted me-2 fs-8">Dibuat Pada :</span>
                                                            <span class="badge rounded-pill bg-gray-lt text-dark fw-semibold px-3">
                                                                <div className="d-flex align-items-center">
                                                                    <FiClock className="me-2 fs-7 text-primary"/>
                                                                    {proyek.created_at}
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="align-self-start ms-3">
                                                        <button className="btn p-0 link-secondary fs-5" onClick={(e)=>this.showEditProyek(proyek)}>
                                                            <span className="fs-7 text-primary me-2">Edit Proyek</span>
                                                            <FiEdit/>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="mt-5">
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <thead>
                                                                <tr className="text-uppercase">
                                                                    <th scope="col" className="fw-normal">Daftar Tugas</th>
                                                                    <th scope="col" className="fw-normal">Deadline</th>
                                                                    <th scope="col" className="fw-normal">Penerima</th>
                                                                    <th scope="col" width="200" className="fw-normal">Progress</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {tugas.map(task=>(
                                                                    <tr>
                                                                        <th scope="row" className="align-middle fs-6">
                                                                            <Link to={`/tugas/detail/${task.id_tugas}`} className="link-dark">{task.nama_tugas}</Link>
                                                                        </th>
                                                                        <td className="align-middle"><span className="badge bg-warning">{task.deadline}</span></td>
                                                                        <td className="align-middle text-center py-3">
                                                                            <div class="avatar-list-stacked">
                                                                                <span class="avatar">
                                                                                    <Avatar data={task.penerima} circle/>
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="align-middle" width="200">
                                                                            <span className="fs-8 mb-1 d-inline-block">
                                                                                {task.progress==="100"?
                                                                                    <>Selesai</>
                                                                                :
                                                                                    <>{task.progress}%</>
                                                                                }
                                                                            </span>
                                                                            <div class="progress" style={{height:"4px"}}>
                                                                                <div class="progress-bar bg-success" role="progressbar" style={{width:task.progress+"%"}}></div>
                                                                            </div>
                                                                        </td>
                                                                        {/* <td className="align-middle text-center">
                                                                            <a href='#' className="link-secondary">Edit Tugas <FiEdit className="fs-6"/></a>
                                                                        </td> */}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* edit proyek */}
                <Modal show={edit_proyek.is_open} onHide={this.hideEditProyek} dialogClassName="modal-smd">
                    <form onSubmit={this.updateProyek}>
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold fs-5">Edit Proyek</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <label for="input-namaproyek" class="form-label fw-semibold">Nama Proyek</label>
                            <OverlayTrigger
                                trigger="focus"
                                placement="right"
                                overlay={
                                    !isUndefined(form_validation_edit_proyek.nama_proyek)?
                                    <Popover id="popover-ddf" className="popover-danger">
                                        <Popover.Body>
                                            <span class="text-white">Nama Proyek Harus diisi!</span>
                                        </Popover.Body>
                                    </Popover>
                                    :<></>
                                }
                            >
                                <input 
                                    type="text" 
                                    class={classNames("form-control", {"is-invalid":!isUndefined(form_validation_edit_proyek.nama_proyek)})}
                                    id="input-namaproyek" 
                                    placeholder="Uraian ..."
                                    name="nama_proyek"
                                    value={edit_proyek.nama_proyek}
                                    onChange={this.typeEditProyek}
                                />
                            </OverlayTrigger>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between mt-0">
                        <button type="button" className="btn btn-link text-decoration-none" onClick={this.hideEditProyek}>
                            Close
                        </button>
                        <button type="submit" className="btn btn-primary" disabled={is_loading}>
                            Save Changes
                        </button>
                    </Modal.Footer>
                    </form>
                </Modal>
            </>
        )
    }
}

export default withAuth(DetailProyek)