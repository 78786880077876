import React from "react"
import update from "immutability-helper"
import classNames from "classnames"
import { Helmet } from "react-helmet"
import Toggle from "react-toggle"
import { AiFillWindows, AiOutlineAppstore, AiOutlineBell } from "react-icons/ai"
import { FiAlertCircle, FiAward, FiBell, FiHome, FiLayers, FiLogOut, FiPlus, FiPlusCircle, FiSettings, FiShare, FiThumbsUp, FiTrello, FiUser, FiShare2, FiBriefcase, FiCheck } from "react-icons/fi"
import { BsBookmarkPlus, BsHouse, BsLayers, BsNewspaper, BsPlus, BsPlusCircle, BsStarHalf } from "react-icons/bs"
import {BiBuildingHouse,BiSitemap} from "react-icons/bi"
import { Button, Dropdown, Modal, Offcanvas, Alert, ProgressBar} from "react-bootstrap"
import { Link, Switch, Route, Redirect, withRouter } from "react-router-dom"
import RouterContext from "../../store/router_context"
import {access_token, get_instansi_selected, goto_page, is_login, login_data, remove_login_data, user_id} from "../../config/config"
import { API } from "../../config/api"
import Avatar from "../../component/ui/avatar"
import { messaging, vapid_key } from '../../config/firebase'
import { getToken, onMessage, getMessaging } from "firebase/messaging"

//routes pages
import StartWizard from "./start_wizard"
import BlankPage from "./start_wizard/blank"
import DashboardPage from "./dashboard"
import AddTugas from "./tugas/add_tugas"
import Jabatan from "./jabatan"
import AddInstansi from "./instansi/add_instansi"
import Absensi from "./absensi"
import Timeline from "./timeline"
import Kpi from "./kpi"
import KpiDetail from "./kpi/detail"
import Member from "./member"
import Tugas from "./tugas"
import Proyek from "./proyek"
import RiwayatTugas from "./tugas/riwayat"
import DetailProyek from "./proyek/detail"
import DetailTugas from "./tugas/detail"
import Profile from "./profile"
import { FaTrash } from "react-icons/fa"
import { CSSTransition, SwitchTransition, TransitionGroup } from "react-transition-group"
import { pusher } from "../../config/pusher"

class Dashboard extends React.Component{
    state={
        notification:{
            is_open:false,
            count_unread:0,
            data:[]
        },
        instansi:[],
        active_menu:"",
        user:{
            id_user:"",
            nama_lengkap:"",
            nama_2:"",
            avatar_url:"",
            status:"",
            access_token:"",
            expired:"",
            login_type:""
        },
        online_users:[],
        show_alert_unverified:false
    }

    componentDidMount=()=>{
        this.getListInstansi()
        if(is_login()){
            this.setState({
                user:login_data()
            })
            if(login_data().status==="unverified"){
                this.setState({
                    show_alert_unverified:true
                })
            }

            const private_channel=pusher(access_token()).subscribe("private-user_"+user_id())
            private_channel.bind("notif", data=>{
                this.getUnreadNotifikasi()
            })
        }

        if(get_instansi_selected().id_instansi!==""){
            const channel=pusher(access_token()).subscribe("presence-instansi_"+get_instansi_selected().id_instansi)
            channel.bind("pusher:subscription_succeeded", (members)=>{
                this.setState({
                    online_users:Object.keys(channel.members.members)
                })
            })
            channel.bind("pusher:member_added", members=>{
                this.setState({
                    online_users:Object.keys(channel.members.members)
                })
            })
            channel.bind("pusher:member_removed", members=>{
                this.setState({
                    online_users:Object.keys(channel.members.members)
                })
            })
        }

        //firebase
        this.requestPermissionNotification()
    }
    requestPermissionNotification=()=>{
        if(!('Notification' in window)){
            console.log("api not supported")
        }
        else if(Notification.permission==="granted"){
            this.getTokenNotification()
        }
        else if(Notification.permission!=="denied"){
            Notification.requestPermission().then(perm=>{
                if(perm==='granted'){
                    this.getTokenNotification()
                }
            })
        }
    }
    getTokenNotification=()=>{
        getToken(messaging, {vapidKey:vapid_key})
        .then(curToken=>{
            if(curToken){
                API.post("/authentication/update_token_messaging", JSON.stringify({
                    token:access_token(),
                    notif_token:curToken
                }))
                .catch(err=>{
                    switch (err.response.status) {
                        case 401:
                            remove_login_data()    
                        break;
                    }
                })
            }
            else{
                console.log("no registration token available")
            }
        })
    }
    getListInstansi=()=>{
        API.post("/dashboard/instansi/get_list_instansi", JSON.stringify({
            token:access_token()
        }))
        .then(res=>{
            this.setState({
                instansi:res.data.instansi
            }, ()=>{
                this.getUnreadNotifikasi()
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }
    getUnreadNotifikasi=()=>{
        API.post("/dashboard/notifikasi/count_unread", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi
        }))
        .then(res=>{
            this.setState({
                notification:update(this.state.notification, {
                    count_unread:{$set:res.data.count_unread}
                })
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }
    getListNotifikasi=()=>{
        API.post("/dashboard/notifikasi/list_notifikasi", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi
        }))
        .then(res=>{
            this.setState({
                notification:update(this.state.notification, {
                    data:{$set:res.data.data}
                })
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }
    setActiveInstansi=instansi=>{
        localStorage.setItem("instansi_selected", JSON.stringify({
            id_instansi:instansi.id_instansi,
            created_by:instansi.created_by,
            jabatan_type:instansi.jabatan_type
        }))
        goto_page("/")
    }

    //instansi
    applyInstansi=(notif_id, jabatan_id)=>{
        API.post("/dashboard/instansi/apply_instansi", JSON.stringify({
            token:access_token(),
            id_jabatan:jabatan_id
        }))
        .then(res=>{
            this.readNotifikasi(notif_id)
            this.getListInstansi()
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }
    rejectInstansi=(notif_id, jabatan_id)=>{
        API.post("/dashboard/instansi/reject_instansi", JSON.stringify({
            token:access_token(),
            id_jabatan:jabatan_id
        }))
        .then(res=>{
            this.readNotifikasi(notif_id)
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }

    //notification
    showNotification=(e)=>{
        e.preventDefault()

        this.setState({
            notification:update(this.state.notification, {
                is_open:{$set:true},
                data:{$set:[]}
            })
        }, ()=>{
            this.getListNotifikasi()
        })
    }
    hideNotification=()=>{
        this.setState({
            notification:update(this.state.notification, {
                is_open:{$set:false}
            })
        })
    }
    deleteAllNotification=()=>{
        API.post("/dashboard/notifikasi/delete_all", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi
        }))
        .then(res=>{
            this.hideNotification()
            this.getUnreadNotifikasi()
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }
    readNotifikasi=(notif_id, link_to=false)=>{
        API.post("/dashboard/notifikasi/read_notifikasi", JSON.stringify({
            token:access_token(),
            notif_id:notif_id
        }))
        .then(res=>{
            this.hideNotification()
            this.getUnreadNotifikasi()
            if(link_to!==false){
                this.props.history.push(link_to)
            }
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }
    renderNotifikasi=notif=>{
        switch(notif.notifikasi_type){
            case "update_status":
                return (
                    <button 
                        class={classNames("list-group-item mb-1 border-0 p-3", {"bg-blue-lt":notif.unread}, {"border-bottom":!notif.unread})}
                        onClick={()=>this.readNotifikasi(notif.id_notifikasi, `/r-tugas/detail/${notif.data.id_tugas}`)}
                    >
                        <span className="avatar avatar-xmd notification-icon">
                            <Avatar data={notif.pengirim} circle/>
                        </span>
                        <div className="w-100 bd-highlight ps-3">
                            <div className="d-flex justify-content-between">
                                <h4 className="fs-7 fw-bold mb-0 text-truncate">{notif.pengirim.nama_lengkap}</h4>
                                <span className="text-muted fs-8 text-nowrap">{notif.created_at}</span>
                            </div>
                            <p className="mb-0 mt-2 text-start">Mengupdate Status di Tugas <span className="text-primary">{notif.data.nama_tugas}</span></p>
                        </div>
                    </button>
                )
            break;
            case "tugas_baru":
                return (
                    <button 
                        class={classNames("list-group-item mb-1 border-0 p-3", {"bg-blue-lt":notif.unread}, {"border-bottom":!notif.unread})}
                        onClick={()=>this.readNotifikasi(notif.id_notifikasi, `/r-tugas/detail/${notif.data.id_tugas}`)}
                    >
                        <span className="avatar avatar-xmd notification-icon">
                            <Avatar data={notif.pengirim} circle/>
                        </span>
                        <div className="w-100 bd-highlight ps-3">
                            <div className="d-flex justify-content-between">
                                <h4 className="fs-7 fw-bold mb-0 text-truncate">{notif.pengirim.nama_lengkap}</h4>
                                <span className="text-muted fs-8 text-nowrap">{notif.created_at}</span>
                            </div>
                            <p className="mb-0 mt-2 text-start">Mengirim Tugas <span className="text-primary">{notif.data.nama_tugas}</span></p>
                        </div>
                    </button>
                )
            break;
            case "undang_instansi":
                return (
                    <span class={classNames("list-group-item mb-1 border-0 p-3", {"bg-blue-lt":notif.unread}, {"border-bottom":!notif.unread})}>
                        <span className="avatar avatar-xmd notification-icon">
                            <Avatar data={notif.pengirim} circle/>
                        </span>
                        <div className="w-100 bd-highlight ps-3">
                            <div className="d-flex justify-content-between">
                                <h4 className="fs-7 fw-bold mb-0 text-truncate">{notif.pengirim.nama_lengkap}</h4>
                                <span className="text-muted fs-8 text-nowrap">{notif.created_at}</span>
                            </div>
                            <p className="mb-0 mt-2 text-start">Mengundang Anda untuk Jabatan "{notif.data.nama_jabatan}" di Instansi <span className="text-primary">{notif.data.nama_instansi}</span></p>
                            {notif.unread&&
                                <div className="d-flex mt-3">
                                    <button 
                                        className="btn btn-success btn-sm px-3" 
                                        onClick={()=>this.applyInstansi(notif.id_notifikasi, notif.data.id_jabatan)}
                                    >
                                        Terima
                                    </button>
                                    <button 
                                        className="btn btn-danger btn-sm ms-2 px-3"
                                        onClick={()=>this.rejectInstansi(notif.id_notifikasi, notif.data.id_jabatan)}
                                    >
                                        Tolak
                                    </button>
                                </div>
                            }
                        </div>
                    </span>
                )
            break;
            case "apply_instansi":
                return (
                    <button 
                        class={classNames("list-group-item mb-1 border-0 p-3", {"bg-blue-lt":notif.unread}, {"border-bottom":!notif.unread})}
                        onClick={()=>this.readNotifikasi(notif.id_notifikasi, `/jabatan`)}
                    >
                        <span className="avatar avatar-xmd notification-icon">
                            <Avatar data={notif.pengirim} circle/>
                        </span>
                        <div className="w-100 bd-highlight ps-3">
                            <div className="d-flex justify-content-between">
                                <h4 className="fs-7 fw-bold mb-0 text-truncate">{notif.pengirim.nama_lengkap}</h4>
                                <span className="text-muted fs-8 text-nowrap">{notif.created_at}</span>
                            </div>
                            <p className="mb-0 mt-2 text-start">Menyetujui undangan jabatan "{notif.data.nama_jabatan}" di Instansi <span className="text-primary">{notif.data.nama_instansi}</span></p>
                        </div>
                    </button>
                )
            break;
            case "reject_instansi":
                return (
                    <button 
                        class={classNames("list-group-item mb-1 border-0 p-3", {"bg-blue-lt":notif.unread}, {"border-bottom":!notif.unread})}
                        onClick={()=>this.readNotifikasi(notif.id_notifikasi, `/jabatan`)}
                    >
                        <span className="avatar avatar-xmd notification-icon">
                            <Avatar data={notif.pengirim} circle/>
                        </span>
                        <div className="w-100 bd-highlight ps-3">
                            <div className="d-flex justify-content-between">
                                <h4 className="fs-7 fw-bold mb-0 text-truncate">{notif.pengirim.nama_lengkap}</h4>
                                <span className="text-muted fs-8 text-nowrap">{notif.created_at}</span>
                            </div>
                            <p className="mb-0 mt-2 text-start">Menolak undangan jabatan "{notif.data.nama_jabatan}" di Instansi <span className="text-primary">{notif.data.nama_instansi}</span></p>
                        </div>
                    </button>
                )
            break;
            case "tag_komentar":
                return (
                    <button 
                        class={classNames("list-group-item mb-1 border-0 p-3", {"bg-blue-lt":notif.unread}, {"border-bottom":!notif.unread})}
                        onClick={()=>this.readNotifikasi(notif.id_notifikasi, `/r-tugas/detail/${notif.data.id_tugas}`)}
                    >
                        <span className="avatar avatar-xmd notification-icon">
                            <Avatar data={notif.pengirim} circle/>
                        </span>
                        <div className="w-100 bd-highlight ps-3">
                            <div className="d-flex justify-content-between">
                                <h4 className="fs-7 fw-bold mb-0 text-truncate">{notif.pengirim.nama_lengkap}</h4>
                                <span className="text-muted fs-8 text-nowrap">{notif.created_at}</span>
                            </div>
                            <p className="mb-0 mt-2 text-start" dangerouslySetInnerHTML={{__html:notif.data.komentar}}/>
                        </div>
                    </button>
                )
            break;
        }
    }

    //logout
    logout=()=>{
        API.post("/authentication/logout", JSON.stringify({
            token:access_token()
        }))
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    localStorage.removeItem("user_data")
                    localStorage.removeItem("instansi_selected")
                    window.location.href="/?status=logout"  
                break;
            }
        })
    }

    //alert
    showAlertUnverified=()=>{
        this.setState({
            show_alert_unverified:true
        })
    }
    hideAlertUnverified=()=>{
        this.setState({
            show_alert_unverified:false
        })
    }

    //context
    setActiveMenu=menu=>{
        this.setState({
            active_menu:menu
        })
    }
    getActiveIndicatorPosition=()=>{
        let pos
        switch(this.state.active_menu){
            case "/":
                pos=this.homeMenu.current.getBoundingClientRect().left
            break;
            case "/absensi":
                pos=this.absensiMenu.current.getBoundingClientRect().left
            break;
            case "/tugas/add":
                pos=this.plusMenu.current.getBoundingClientRect().left
            break;
            case "/timeline":
                pos=this.timelineMenu.current.getBoundingClientRect().left
            break;
            case "/kpi":
                pos=this.kpiMenu.current.getBoundingClientRect().left
            break;
            default:
                pos=-50
        }

        return pos-12
    }
    getActiveIndicatorPositionDesk=()=>{
        let pos, menu, wrapper
        switch(this.state.active_menu){
            case "/":
                wrapper=this.menuWrapperDesk.current.getBoundingClientRect().left
                menu=this.homeDeskMenu.current.getBoundingClientRect()
                pos={
                    left:menu.left-wrapper+12,
                    width:menu.width
                }
            break;
            case "/absensi":
                wrapper=this.menuWrapperDesk.current.getBoundingClientRect().left
                menu=this.absensiDeskMenu.current.getBoundingClientRect()
                pos={
                    left:menu.left-wrapper+12,
                    width:menu.width
                }
            break;
            case "/timeline":
                wrapper=this.menuWrapperDesk.current.getBoundingClientRect().left
                menu=this.timelineDeskMenu.current.getBoundingClientRect()
                pos={
                    left:menu.left-wrapper+12,
                    width:menu.width
                }
            break;
            case "/kpi":
                wrapper=this.menuWrapperDesk.current.getBoundingClientRect().left
                menu=this.kpiDeskMenu.current.getBoundingClientRect()
                pos={
                    left:menu.left-wrapper+12,
                    width:menu.width
                }
            break;
            default:
                pos={
                    left:0,
                    visibility:"hidden"
                }
        }

        return pos
    }
    routerContextValue=()=>{
        return {
            setActiveMenu:this.setActiveMenu,
            getUnreadNotifikasi:this.getUnreadNotifikasi,
            getListInstansi:this.getListInstansi,
            online_users:this.state.online_users
        }
    }

    //refs menu
    homeMenu=React.createRef()
    absensiMenu=React.createRef()
    plusMenu=React.createRef()
    timelineMenu=React.createRef()
    kpiMenu=React.createRef()
    homeDeskMenu=React.createRef()
    absensiDeskMenu=React.createRef()
    timelineDeskMenu=React.createRef()
    kpiDeskMenu=React.createRef()
    menuWrapperDesk=React.createRef()

    render(){
        const {notification, instansi, active_menu, user, show_alert_unverified}=this.state
        
        return (
            <RouterContext.Provider value={this.routerContextValue()}>
                <header class="header-layout p-2">
                    <div class="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                                    <a href="/" class="navbar-brand">
                                        <img src="/logo.png" height="40"/>
                                    </a>
                                    <div className="d-flex ms-auto me-3">
                                        <a href='#' className="notification-bar link-dark text-decoration-none position-relative me-1" onClick={this.showNotification}>
                                            <AiOutlineBell className="mt-0"/>
                                            {notification.count_unread>0&&
                                                <span className="notification-dot"></span>
                                            }
                                        </a>
                                    </div>
                                    <Dropdown align="end">
                                        <Dropdown.Toggle as="a" className="d-flex align-items-center cursor-pointer link-dark text-decoration-none dropdown-toggle no-caret">
                                            <span className="avatar">
                                                <Avatar data={user} circle/>
                                            </span>
                                            <span className="ms-2">{user.nama_lengkap}</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{width:"200px", marginTop:"17px"}}>
                                            <Link to="/profile" className="dropdown-item">
                                                <span className="dropdown-icon"><FiUser/></span>
                                                <span>Profile</span>
                                            </Link>
                                            <Dropdown.Divider></Dropdown.Divider>
                                            {get_instansi_selected().id_instansi!==""&&
                                                <Link to="/jabatan" className="dropdown-item">
                                                    <span className="dropdown-icon"><BiSitemap/></span>
                                                    <span>Jabatan</span>
                                                </Link>
                                            }
                                            <Dropdown.Header>
                                                <span className="dropdown-icon"><BiBuildingHouse/></span>
                                                <span className="text-dark">Instansi Saya</span>
                                            </Dropdown.Header>
                                            <div className="list-scroll">
                                                {instansi.map(ins=>(
                                                    <Dropdown.Item as="button" className="dropdown-no-icon text-wrap pe-0">
                                                        <span 
                                                            className={classNames({"fw-bold":ins.id_instansi===get_instansi_selected().id_instansi})}
                                                            onClick={()=>this.setActiveInstansi(ins)}
                                                        >
                                                            {ins.nama_instansi}
                                                        </span>
                                                    </Dropdown.Item>
                                                ))}
                                            </div>
                                            <Dropdown.Header className="dropdown-no-icon d-grid grip-1">
                                                <Link to="/instansi/add" className="btn btn-secondary btn-sm fs-9" style={{paddingTop:"3px", paddingBottom:"3px"}}>
                                                    + Tambah Instansi
                                                </Link>
                                            </Dropdown.Header>
                                            <Dropdown.Divider></Dropdown.Divider>
                                            <Dropdown.Item href="">
                                                <span className="dropdown-icon"><FiSettings/></span>
                                                <span>Pengaturan</span>
                                            </Dropdown.Item>
                                            <Dropdown.Item href="">
                                                <span className="dropdown-icon"><FiAlertCircle/></span>
                                                <span>Term of Service</span>
                                            </Dropdown.Item>
                                            <Dropdown.Item as="button" onClick={this.logout}>
                                                <span className="dropdown-icon"><FiLogOut/></span>
                                                <span>Keluar</span>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                
                {user.status==="unverified"&&
                    <Alert show={show_alert_unverified} key="notifikasi" variant="warning" className="mt-0">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 mx-auto">
                                    <div className="d-flex align-items-center">
                                        <p className="mb-0 fw-bold">Verifikasi email anda untuk mengaktifkan fitur aplikasi Garapan ID</p>
                                        <button type="button" class="btn-close ms-auto" onClick={this.hideAlertUnverified}></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Alert>
                }

                <div className="menu d-none d-md-block">
                    <div class="container mt-4">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="row">
                                    <div className="col position-relative">
                                        <ul class="nav nav-pills nav-menu d-flex justify-content-around" ref={this.menuWrapperDesk}>
                                            <li class="nav-item">
                                                <Link to="/" class={classNames("nav-link link-secondary", {"link-primary active":active_menu==="/"})} ref={this.homeDeskMenu}>
                                                    <BsHouse className="nav-icon"/>
                                                    Dashboard
                                                </Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link to="/absensi" class={classNames("nav-link link-secondary", {"link-primary active":active_menu==="/absensi"})} ref={this.absensiDeskMenu}>
                                                    <BsLayers className="nav-icon"/>
                                                    Absensi
                                                </Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link to="/timeline"  class={classNames("nav-link link-secondary", {"link-primary active":active_menu==="/timeline"})} ref={this.timelineDeskMenu}>
                                                    <BsNewspaper className="nav-icon"/>
                                                    Timeline
                                                </Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link to="/kpi"  class={classNames("nav-link link-secondary", {"link-primary active":active_menu==="/kpi"})} ref={this.kpiDeskMenu}>
                                                    <BsStarHalf className="nav-icon"/>
                                                    Key Performance Indicator
                                                </Link>
                                            </li>
                                        </ul>
                                        <div className="active-indicator" style={this.getActiveIndicatorPositionDesk()}></div>
                                    </div>
                                    {get_instansi_selected().jabatan_type.includes("atasan")&&
                                        <div className="col-md-3 d-grid grip-2">
                                            <Link to="/tugas/add" className="btn btn-primary fw-bold mt-3 mt-md-0 d-flex align-items-center justify-content-center">
                                                Tambah Tugas
                                                <BsPlus className="nav-icon me-0 ms-2 fs-4"/>
                                            </Link>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Switch location={this.props.location}>
                    <Route exact path="/" component={DashboardPage}/>
                    <Route exact path="/blank" component={BlankPage}/>
                    <Route exact path="/start_wizard" component={StartWizard}/>
                    <Route exact path="/tugas" component={Tugas}/>
                    <Route exact path="/tugas/add" component={AddTugas}/>
                    <Route exact path="/tugas/riwayat" component={RiwayatTugas}/>
                    <Route exact path="/tugas/detail/:id_tugas" component={DetailTugas}/>
                    <Route exact path="/proyek" component={Proyek}/>
                    <Route exact path="/proyek/detail/:id_proyek" component={DetailProyek}/>
                    <Route exact path="/jabatan" component={Jabatan}/>
                    <Route exact path="/instansi/add" component={AddInstansi}/>
                    <Route exact path="/absensi" component={Absensi}/>
                    <Route exact path="/timeline" component={Timeline}/>
                    <Route exact path="/kpi" component={Kpi}/>
                    <Route exact path="/kpi/detail/:id_user" component={KpiDetail}/>
                    <Route exact path="/member" component={Member}/>
                    <Route exact path="/profile" component={Profile}/>
                    
                    {/* REDIRECT */}
                    <Redirect from="/r-tugas/detail/:id_tugas" to="/tugas/detail/:id_tugas"/>
                </Switch>

                <div className="footer-nav-area d-block d-md-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div class="footer-nav position-relative shadow-sm">
                                    <ul class="h-100 d-flex align-items-center justify-content-between ps-0">
                                        <li className={classNames({"active":active_menu==="/"})} ref={this.homeMenu}>
                                            <Link to="/">
                                                <BsHouse className="footer-nav-icon"/>
                                            </Link>
                                        </li>
                                        <li className={classNames({"active":active_menu==="/absensi"})} ref={this.absensiMenu}>
                                            <Link to="/absensi">
                                                <BsLayers className="footer-nav-icon"/>
                                            </Link>
                                        </li>
                                        {get_instansi_selected().jabatan_type.includes("atasan")&&
                                            <li className={classNames({"active":active_menu==="/tugas/add"})} ref={this.plusMenu}>
                                                <Link to="/tugas/add">
                                                    <BsPlus className="footer-nav-icon"/>    
                                                </Link>
                                            </li>
                                        }
                                        <li className={classNames({"active":active_menu==="/timeline"})} ref={this.timelineMenu}>
                                            <Link to="/timeline">
                                                <BsNewspaper className="footer-nav-icon"/>
                                            </Link>
                                        </li>
                                        <li className={classNames({"active":active_menu==="/kpi"})} ref={this.kpiMenu}>
                                            <Link to="/kpi">
                                                <BsStarHalf className="footer-nav-icon"/>
                                            </Link>
                                        </li>
                                    </ul>
                                    <div 
                                        className="active-indicator" 
                                        style={{
                                            left:this.getActiveIndicatorPosition(), 
                                            visibility:this.getActiveIndicatorPosition()>0?"visible":"hidden"
                                        }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* notification */}
                <Offcanvas show={notification.is_open} onHide={this.hideNotification} placement="end">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            <div>
                                <h3 className="fs-5 mb-0 fw-bold">Notifikasi</h3>
                                <p className="fs-7 mb-0 text-muted">Kamu punya <span className="fw-bold text-primary">{notification.count_unread}</span> notifikasi yang belum dibaca</p>
                            </div>
                            <div>
                                <button type="button" className="btn btn-danger btn-sm mt-2" onClick={this.deleteAllNotification}><FaTrash/></button>
                            </div>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div class="list-group notification">
                            {notification.data.map(notif=>this.renderNotifikasi(notif))}
                        </div>
                        
                    </Offcanvas.Body>
                </Offcanvas>

                {/* progress */}
            </RouterContext.Provider>
            
        )
    }
}

export default withRouter(Dashboard)