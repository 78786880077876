import React, { Component } from 'react'
import moment from "moment"
import { Calendar, momentLocalizer } from 'react-big-calendar'
import withAuth from '../../../component/hoc/auth'
import { API } from '../../../config/api'
import { access_token, get_instansi_selected, remove_login_data } from '../../../config/config'
import { Modal } from 'react-bootstrap'
import Avatar from '../../../component/ui/avatar'

let localizer=momentLocalizer(moment)
class Timeline extends Component {
    state={
        timeline:[],
        detail_timeline:{
            is_open:false,
            data:{}
        }
    }
    
    componentDidMount=()=>{
        this.getListTugas()
    }
    getListTugas=()=>{
        API.post("/dashboard//timeline/list_tugas", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi
        }))
        .then(res=>{
            const timelines=res.data.data.map(tl=>{
                return Object.assign(tl, {
                    start:new Date(tl.start*1000),
                    end:new Date(tl.end*1000)
                })
            })
            this.setState({
                timeline:timelines
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }
    eventColor=(event, start, end, isSelected)=>{
        const data=event.data

        let bg_color
        if(data.status==="sent"){
            bg_color="#8a8f96"
        }
        else if(data.status==="progress"){
            bg_color="#45aaf2"
        }
        else if(data.status=="problem"){
            bg_color="#cd201f"
        }
        else if(data.status==="delivery"){
            bg_color="#a55eea"
        }
        else if(data.status==="complete"){
            bg_color="#5eba00"
        }
        else if(data.status==="continue"){
            bg_color="#206bc4"
        }

        return {
            style:{
                backgroundColor: bg_color,
                color: '#fff',
                border: '0px',
                display: 'block'
            }
        }
    }

    //detail timeline
    showModalDetailTimeline=(event)=>{
        this.setState({
            detail_timeline:{
                is_open:true,
                data:event.data
            }
        })
    }
    closeModalDetailTimeline=(e)=>{
        this.setState({
            detail_timeline:{
                is_open:false,
                data:{}
            }
        })
    }
    getStatus=(status)=>{
        if(status=="sent"){
            return <span class="badge bg-gray">Terkirim</span>
        }
        else if(status=="progress"){
            return <span className="badge bg-azure">Sedang Dikerjakan</span>
        }
        else if(status=="problem"){
            return <span className="badge bg-red">Ada Masalah</span>
        }
        else if(status==="delivery"){
            return <span className="badge bg-purple">Mohon Dicek</span>
        }
        else if(status==="complete"){
            return <span className="badge bg-green">Selesai</span>
        }
        else if(status==="continue"){
            return <span className="badge bg-blue">Lanjutkan</span>
        }
    }

    render() {
        const {timeline, detail_timeline}=this.state

        return (
            <>
                <div className="content mt-4 mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div class="card">
                                    <div class="card-header d-flex justify-content-between">
                                        <h5 className="card-title text-truncate">Timeline Tugas</h5>
                                    </div>
                                    <div className="card-body p-4">
                                        <Calendar
                                            localizer={localizer}
                                            events={timeline}
                                            startAccessor="start"
                                            endAccessor="end"
                                            style={{ minHeight: 800 }}
                                            step={30}
                                            eventPropGetter={this.eventColor}
                                            onSelectEvent={(event)=>this.showModalDetailTimeline(event)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MODAL DETAIL TIMELINE */}
                <Modal show={detail_timeline.is_open} onHide={this.closeModalDetailTimeline}>
                    <Modal.Body>
                        <div className="d-flex mb-3">
                            <button type="button" class="close ms-auto" onClick={this.closeModalDetailTimeline}>
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"/><line x1="18" y1="6" x2="6" y2="18" /><line x1="6" y1="6" x2="18" y2="18" /></svg>
                            </button>
                        </div>
                        <div>
                            <div class="table-responsive">
                                <table class="table table-vcenter">
                                    <tbody>
                                        <tr>
                                            <td width="130" className="pl-0">
                                                <span className="text-muted">Status</span>
                                            </td>
                                            <td className="pr-0">
                                                {this.getStatus(detail_timeline.data.status)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="130" className="pl-0">
                                                <span className="text-muted">Tugas dari</span>
                                            </td>
                                            {typeof detail_timeline.data.pengirim!=="undefined"&&
                                                <td className="pr-0">
                                                    <div class="d-flex justify-content-start align-items-center">
                                                        <span className="avatar rounded me-2">
                                                            <Avatar data={detail_timeline.data.pengirim} circle/>
                                                        </span>
                                                        <span>{detail_timeline.data.pengirim.nama_lengkap}</span>
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                        <tr>
                                            <td width="130" className="pl-0">
                                                <span className="text-muted">Tugas Kepada</span>
                                            </td>
                                            {typeof detail_timeline.data.penerima!=="undefined"&&
                                                <td className="pr-0">
                                                    <div class="d-flex justify-content-start align-items-center">
                                                        <span className="avatar rounded me-2">
                                                            <Avatar data={detail_timeline.data.penerima} circle/>
                                                        </span>
                                                        <span>{detail_timeline.data.penerima.nama_lengkap}</span>
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                        <tr>
                                            <td width="130" className="pl-0">
                                                <span className="text-muted">Proyek</span>
                                            </td>
                                            <td className="pr-0">
                                                {detail_timeline.data.nama_proyek}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="130" className="pl-0">
                                                <span className="text-muted">Tugas</span>
                                            </td>
                                            <td className="pr-0">
                                                {detail_timeline.data.nama_tugas}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="130" className="pl-0">
                                                <span className="text-muted">Progress</span>
                                            </td>
                                            <td className="pr-0">
                                                {detail_timeline.data.progress}%
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="130" className="pl-0">
                                                <span className="text-muted">Deskripsi</span>
                                            </td>
                                            <td className="pr-0">
                                                {detail_timeline.data.deskripsi}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default withAuth(Timeline)