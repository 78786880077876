import React, { Component } from 'react'
import qs from "querystring"
import classNames from "classnames"
import update from "immutability-helper"
import { Modal, Offcanvas, OverlayTrigger, Popover } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import { BiUpload } from 'react-icons/bi'
import { BsArrowLeft } from 'react-icons/bs'
import { FiCheck, FiX } from "react-icons/fi"
import Toggle from 'react-toggle'
import withAuth from '../../../component/hoc/auth'
import { API } from '../../../config/api'
import { access_token, BASE_URL, get_instansi_selected, remove_login_data, isUndefined } from '../../../config/config'
import RouterContext from '../../../store/router_context'
import { toast } from 'react-toastify'

class AddInstansi extends Component{
    state={
        tambah_instansi:{
            nama_instansi:""
        },
        form_validation_tambah_instansi:{},
        is_loading:false
    }

    //tambah instansi
    typeTambahInstansi=e=>{
        const target=e.target

        this.setState({
            tambah_instansi:update(this.state.tambah_instansi, {
                [target.name]:{$set:target.value}
            })
        })
    }
    tambahInstansi=e=>{
        e.preventDefault()
        const context=this.context

        this.setState({is_loading:true})
        API.post("/dashboard/instansi/add_instansi", JSON.stringify({
            token:access_token(),
            nama_instansi:this.state.tambah_instansi.nama_instansi
        }))
        .then(res=>{
            this.setState({
                is_loading:false,
            })
            context.getListInstansi()
            toast.success("Instansi berhasil dibuat!")
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
                case 500:
                    this.setState({
                        form_validation_tambah_instansi:err.response.data.status.data
                    })
                break;
            }
            this.setState({is_loading:false})
        })
    }

    render() {
        const {tambah_instansi, form_validation_tambah_instansi, is_loading}=this.state

        return (
            <>
                <div className="content mt-4 mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <form class="card widget-tambah-tugas" onSubmit={this.tambahInstansi}>
                                    <div class="card-header d-flex justify-content-between">
                                        <button type="button" className="btn btn-secondary">
                                            <BsArrowLeft/> Kembali
                                        </button>
                                        <h5 className="card-title text-truncate">Membuat Instansi</h5>
                                    </div>
                                    <div className="col-md-12 mx-auto">
                                        <div className="card-body rounded-bottom-0">
                                            <div className="row mt-3">
                                                <div className="col-md-8 mx-auto">
                                                    <div className="row align-items-center mb-3">
                                                        <label 
                                                            for="nama_instansi" 
                                                            className="col-sm-4 col-form-label fw-semibold text-start text-md-end"
                                                        >
                                                            Nama Instansi
                                                        </label>
                                                        <div className="col-sm-8">
                                                            <OverlayTrigger
                                                                trigger="focus"
                                                                placement="right"
                                                                overlay={
                                                                    !isUndefined(form_validation_tambah_instansi.nama_instansi)?
                                                                    <Popover id="popover-username" className="popover-danger">
                                                                        <Popover.Body>
                                                                            <span class="text-white">Nama Instansi Harus diisi!</span>
                                                                        </Popover.Body>
                                                                    </Popover>
                                                                    :<></>
                                                                }
                                                            >
                                                                <input 
                                                                    type="text" 
                                                                    className={classNames("form-control", {"is-invalid":!isUndefined(form_validation_tambah_instansi.nama_instansi)})}
                                                                    id="nama_instansi" 
                                                                    placeholder="Nama Instansi"
                                                                    onChange={this.typeTambahInstansi}
                                                                    name="nama_instansi"
                                                                    value={tambah_instansi.nama_instansi}
                                                                />
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer d-flex justify-content-end">
                                            <button 
                                                type="submit"
                                                className="btn btn-primary"
                                                disabled={is_loading}
                                            >
                                                Simpan Data
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
AddInstansi.contextType=RouterContext

export default withAuth(AddInstansi)