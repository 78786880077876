import React, { Component } from 'react'
import update from 'immutability-helper'
import classNames from "classnames"
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from "react-helmet"
import Toggle from "react-toggle"
import { FcGoogle } from "react-icons/fc"
import GoogleLogin from 'react-google-login'
import { GOOGLE_OAUTH_CLIENT_ID, goto_page, isUndefined, password_validate, reload_homepage, validateEmail } from '../../config/config'
import { API } from '../../config/api'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { toast } from 'react-toastify'

class Register extends Component {
    state={
        username:"",
        password:"",
        email:"",
        nama_lengkap:"",
        form_validation:{},
        is_loading:false
    }

    setStatus=()=>{
        const params=new URLSearchParams(window.location.search)

        if(this.state.status!==params.get("status")){
            let status
            if(params.get("status")==="failed"){
                status="failed"
                this.props.history.push("?status=failed")
            }
            else if(params.get("status")==="logout"){
                status="logout"
                this.props.history.push("?status=logout")
            }
            else if(params.get("status")==="token_expired"){
                status="token_expired"
                this.props.history.push("?status=token_expired")
            }
            else{
                status="login"
                this.props.history.push("?status=login")
            }

            this.setState({
                status:status
            })
        }
    }
    setLoading=(loading)=>{
        this.setState({
            is_loading:loading
        })
    }

    //register/login
    typeRegister=(e)=>{
        const target=e.target

        let value
        if(target.type==="checkbox") value=target.checked
        else value=target.value

        this.setState({
            [target.name]:value
        })
    }
    register=(e)=>{
        e.preventDefault()
        
        const {username, password, email, nama_lengkap}=this.state
        this.setLoading(true)
        API.post("/authentication/register", JSON.stringify({
            email:email,
            username:username,
            password:password,
            nama_lengkap:nama_lengkap
        }))
        .then(res=>{
            localStorage.setItem("user_data", JSON.stringify(res.data.data))
            goto_page("/")
        })
        .catch(err=>{
            if(err.response.status===500){
                this.setState({
                    form_validation:err.response.data.status.data
                })
            }
            this.setLoading(false)
        })
    }
    loginGoogle=(tokenID)=>{
        API.post("/authentication/verify_login_google", JSON.stringify({
            token:tokenID
        }))
        .then(res=>{
            localStorage.setItem("user_data", JSON.stringify(res.data.data))
            goto_page("/")
        })
        .catch(err=>{
            if(err.response.status===401){
                switch(err.response.data.status.err_code){
                    case "ERR_LOGIN_FAIL":
                        this.props.history.push("/login?status=failed")
                    break;
                    case "ERR_EMAIL_USED":
                        toast.error("E-Mail Sudah digunakan, tidak bisa login dengan google!");
                    break;
                }
            }
        })
    }

    //refs
    inputPassword=React.createRef()

    render() {
        const {username, password, email, nama_lengkap, form_validation, is_loading}=this.state

        return (
            <>
                <Helmet>
                    <style>
                        {'body{background:#fff}'}
                    </style>
                </Helmet>

                <div className="container px-4">
                    <div className="row align-items-center" style={{minHeight:"100vh"}}>
                        <div className="col-md-4 py-5">
                            <h2 className="fw-bold">Daftarkan Akun Anda</h2>
                            <form noValidate className="form-login mt-4" onSubmit={this.register}>
                                <div className="mb-3 position-relative">
                                    <OverlayTrigger
                                        trigger="focus"
                                        placement="right"
                                        overlay={
                                            !isUndefined(form_validation.username)?
                                            <Popover id="popover-username" className="popover-danger">
                                                <Popover.Body>
                                                    <span class="text-white">Username Tidak Valid!</span>
                                                </Popover.Body>
                                            </Popover>
                                            :<></>
                                        }
                                    >
                                        <input 
                                            type="text" 
                                            className={classNames("form-control", {"is-invalid":!isUndefined(form_validation.username)})}
                                            placeholder="Username"
                                            onChange={this.typeRegister}
                                            name="username"
                                            value={username}
                                            autoCorrect={false}
                                        />
                                    </OverlayTrigger>
                                </div>
                                <div className="mb-3 position-relative">
                                    <OverlayTrigger
                                        trigger="focus"
                                        placement="right"
                                        overlay={
                                            !isUndefined(form_validation.password)?
                                            <Popover id="popover-password" className="popover-danger">
                                                <Popover.Body>
                                                    {form_validation.password!=="password_strength"?
                                                        <span class="text-white">Password Harus Diisi!</span>
                                                    :
                                                        <span class="text-white">Password Minimal 8 Karakter kombinasi Huruf dan Angka!</span>
                                                    }
                                                </Popover.Body>
                                            </Popover>
                                            :<></>
                                        }
                                    >
                                        <input 
                                            type="password" 
                                            className={classNames("form-control", {"is-invalid":!isUndefined(form_validation.password)})}
                                            placeholder="Password"
                                            onChange={this.typeRegister}
                                            name="password"
                                            value={password}
                                            minLength={8}
                                        />
                                    </OverlayTrigger>
                                </div>
                                <div className="mb-3 position-relative">
                                    <OverlayTrigger
                                        trigger="focus"
                                        placement="right"
                                        overlay={
                                            !isUndefined(form_validation.email)?
                                            <Popover id="popover-email" className="popover-danger">
                                                <Popover.Body>
                                                    <span class="text-white">E-Mail Tidak Valid!</span>
                                                </Popover.Body>
                                            </Popover>
                                            :<></>
                                        }
                                    >
                                        <input 
                                            type="email" 
                                            className={classNames("form-control", {"is-invalid":!isUndefined(form_validation.email)})}
                                            placeholder="E-Mail"
                                            onChange={this.typeRegister}
                                            name="email"
                                            value={email}
                                        />
                                    </OverlayTrigger>
                                </div>
                                <div className="mb-3 position-relative">
                                    <OverlayTrigger
                                        trigger="focus"
                                        placement="right"
                                        overlay={
                                            !isUndefined(form_validation.nama_lengkap)?
                                            <Popover id="popover-nama-lengkap" className="popover-danger">
                                                <Popover.Body>
                                                    <span class="text-white">Nama Lengkap Harus  Diisi!</span>
                                                </Popover.Body>
                                            </Popover>
                                            :<></>
                                        }
                                    >
                                        <input 
                                            type="text" 
                                            className={classNames("form-control", {"is-invalid":!isUndefined(form_validation.nama_lengkap)})}
                                            placeholder="Nama Lengkap"
                                            onChange={this.typeRegister}
                                            name="nama_lengkap"
                                            value={nama_lengkap}
                                        />
                                    </OverlayTrigger>
                                </div>
                                <div className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span className="fs-8">
                                            Dengan mendaftar, Anda mengonfirmasi bahwa Anda telah membaca dan menerima Persyaratan Layanan dan Kebijakan Privasi kami.
                                        </span>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <button type="submit" className="btn btn-primary px-5 py-2" disabled={is_loading}>Daftar Sekarang</button>
                                </div>
                                <div className="mb-3">
                                    <div className="block-divider">
                                        <span className="block-divider-text text-gray">Atau Masuk Dengan</span>
                                        <span className="block-divider-line"></span>
                                    </div>
                                </div>
                                <div className="mb-3 d-grid gap-2">
                                    <GoogleLogin
                                        clientId={GOOGLE_OAUTH_CLIENT_ID}
                                        render={renderProps=>(
                                            <button type="button" className="btn btn-outline-secondary py-2" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                                <FcGoogle className="me-2 fs-5"/>
                                                Masuk Dengan Google
                                            </button>
                                        )}
                                        onSuccess={response=>this.loginGoogle(response.tokenId)}
                                        onFailure={()=>console.log("failure")}
                                        cookiePolicy={"single_host_origin"}
                                    />
                                </div>
                                <div className="d-flex justify-content-center">
                                    <span className="text-gray">
                                        Sudah Punya Akun?
                                        <Link to="/" className="ms-1">Masuk</Link>
                                    </span>
                                </div>
                            </form>
                        </div>
                        <div className="col-8 col-md-5 ms-auto d-none d-md-flex align-items-center">
                            <img src="/img/hero-img.png" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Register