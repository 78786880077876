import React, { Component } from 'react'
import classNames from "classnames"
import { geolocated } from 'react-geolocated'
import { Link } from 'react-router-dom'
import withAuth from '../../../component/hoc/auth'
import { API } from '../../../config/api'
import { access_token, get_instansi_selected, remove_login_data } from '../../../config/config'
import { Confirm } from '../../../component/ui/confirm'

class Absensi extends Component {
    state={
        absensi:[],
        filter:{
            tgl_mulai:"",
            tgl_selesai:""
        },
        confirm_cek_out:{
            is_open:false,
            id_absensi:""
        }
    }

    componentDidMount=()=>{
        this.getListAbsensi()

        // if(navigator.geolocation){
        //     navigator.geolocation.getCurrentPosition(pos=>{
        //         console.log(pos)
        //     },
        //     err=>{
        //         alert("please enable gps position!")
        //     }, {
        //         maximumAge:10000,
        //         timeout:5000,
        //         enableHighAccuracy:true
        //     })
        // }
    }
    getListAbsensi=()=>{
        API.post("/dashboard/absensi/get_list_absensi", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi,
            tgl_mulai:this.state.filter.tgl_mulai,
            tgl_selesai:this.state.filter.tgl_selesai
        }))
        .then(res=>{
            this.setState({
                absensi:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()
                break;
            }
        })
    }

    //presensi
    cekIn=()=>{
        API.post("/dashboard/absensi/cek_in", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi
        }))
        .then(res=>{
            this.getListAbsensi()
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()
                break;
            }
        })
    }
    cekOut=id_absensi=>{
        API.post("/dashboard/absensi/cek_out", JSON.stringify({
            token:access_token(),
            id_absensi:id_absensi
        }))
        .then(res=>{
            this.getListAbsensi()
            this.hideCheckOut()
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()
                break;
            }
        })
    }
    typeFilter=(e)=>{
        const target=e.target

        this.setState(prevState=>{
            return {
                ...prevState,
                filter:{
                    ...prevState.filter,
                    [target.name]:target.value
                }
            }
        }, ()=>{
            this.getListAbsensi()
        })
    }

    //confirm cek out
    showCheckOut=id_absensi=>{
        this.setState({
            confirm_cek_out:{
                is_open:true,
                id_absensi:id_absensi
            }
        })
    }
    hideCheckOut=()=>{
        this.setState({
            confirm_cek_out:{
                is_open:false,
                id_absensi:""
            }
        })
    }
    confirmCheckOut=()=>{
        this.cekOut(this.state.confirm_cek_out.id_absensi)
    }

    render() {
        const {absensi, filter, confirm_cek_out}=this.state

        return (
            <>
                <div className="content mt-4 mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div class="card widget-absensi">
                                    <div class="card-header d-flex flex-column flex-sm-row justify-content-between">
                                        <h5 className="card-title text-truncate">Absensi</h5>
                                        <div className="d-flex flex-column flex-md-row justify-content-start align-items-center mx-2 mx-md-4">
                                            <span className="me-2 fw-semibold">Tanggal</span>
                                            <div class="" style={{minWidth:"150px"}}>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name="tgl_mulai"
                                                    value={filter.tgl_mulai}
                                                    onChange={this.typeFilter}
                                                />
                                            </div>
                                            <span className="mx-2 fw-semibold">Sampai</span>
                                            <div class="ms-0 ms-md-2" style={{minWidth:"150px"}}>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name="tgl_selesai"
                                                    value={filter.tgl_selesai}
                                                    onChange={this.typeFilter}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {absensi.map(absen=>(
                                        <div class={classNames("card-body px-4 py-3 mb-3", {"bg-danger-lt":absen.jam_cek_in===""||absen.jam_cek_out===""})}>
                                            <div className="row align-items-center">
                                                <div className="col-4">
                                                    <span className="fw-semibold">{absen.tgl_absensi}</span>
                                                </div>
                                                <div className="col-4 col-md-6">
                                                    <div className="d-flex flex-column flex-md-row">
                                                        <div className="nowrap" style={{minWidth:"90px"}}>
                                                            <span className="text-muted">Cek In :</span> <span className="fw-semibold">{absen.jam_cek_in}</span>
                                                        </div>
                                                        <div className="nowrap ms-md-3" style={{minWidth:"90px"}}>
                                                            <span className="text-muted">Cek Out :</span> <span className="fw-semibold">{absen.jam_cek_out}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4 col-md-2">
                                                    <div className="d-grid grip-2">
                                                        {(absen.jam_cek_in===""||absen.jam_cek_out==="")?
                                                            <>
                                                                {absen.jam_cek_in===""&&
                                                                    <button className="btn btn-outline-dark rounded-pill" onClick={this.cekIn}>Cek In</button>
                                                                }
                                                                {(absen.jam_cek_out===""&&absen.jam_cek_in!=="")&&
                                                                    <button className="btn btn-outline-dark rounded-pill" onClick={(e)=>this.showCheckOut(absen.id_absensi)}>Cek Out</button>
                                                                }
                                                            </>
                                                        :
                                                            <Link to={`/tugas/riwayat/?tgl=${absen.tgl_ori}`} className="btn btn-outline-dark rounded-pill">Riwayat Tugas</Link> 
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* konfirmasi cek out */}
                <Confirm
                    show={confirm_cek_out.is_open}
                    toggle={this.hideCheckOut}
                    title="Yakin"
                    sub_title="Sebelum cek out silahkan cek ulang tugas hari ini!"
                    action={this.confirmCheckOut}
                    action_text="Cek Out"
                    btn_type="btn-primary"
                />
            </>
        )
    }
}

export default withAuth(Absensi)