import React, { Component } from 'react'
import classNames from "classnames"
import update from "immutability-helper"
import { BiBuildingHouse, BiCheck, BiSitemap, BiUserCheck, BiUserPlus } from 'react-icons/bi'
import { FiCheck, FiPauseCircle, FiUserPlus } from "react-icons/fi"
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import withAuth from '../../../component/hoc/auth'
import { Modal, Offcanvas, OverlayTrigger, Popover, Tooltip, Toast, ToastContainer } from 'react-bootstrap'
import { isUndefined, access_token, remove_login_data, get_instansi_selected, user_id } from '../../../config/config'
import { API } from '../../../config/api'
import OrganizationChart from '../../../component/ui/org_chart'
import JabatanContext from '../../../store/jabatan_context'
import { CustomRadioPicker } from '../../../component/ui/custom_input'
import { ConfirmDelete } from '../../../component/ui/confirm'
import Toggle from 'react-toggle'
import { toast } from 'react-toastify'
import { AiFillCheckCircle } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import RouterContext from '../../../store/router_context'
import { messaging, vapid_key } from '../../../config/firebase'
import { getToken, onMessage, getMessaging } from "firebase/messaging"
import { pusher } from '../../../config/pusher'

class StartWizard extends Component {
    state={
        active_tab:1,
        instansi:"",
        tambah_instansi:{
            nama_instansi:""
        },
        form_validation_tambah_instansi:{},
        jabatan:[],
        tambah_jabatan:{
            is_open:false,
            id_instansi:"",
            bawahan_dari:"",
            nama_jabatan:"",
            jabatan_type:"atasan"
        },
        form_validation_tambah_jabatan:{},
        edit_jabatan:{
            is_open:false,
            id_jabatan:"",
            nama_jabatan:""
        },
        form_validation_edit_jabatan:{},
        hapus_jabatan:{
            is_open:false,
            id_jabatan:""
        },
        tambah_pekerja:{
            is_open:false,
            id_jabatan:"",
            email:"",
        },
        hapus_pekerja:{
            is_open:false,
            id_jabatan_user:""
        },
        form_validation_tambah_pekerja:{},
        is_loading:false
    }

    componentDidMount=()=>{
        this.getSelectedInstansi()

        if(get_instansi_selected().id_instansi!==""){
            const private_channel=pusher(access_token()).subscribe("private-instansi_"+get_instansi_selected().id_instansi)
            private_channel.bind("notif", data=>{
                this.getSelectedInstansi()
            })
        }
    }
    componentDidUpdate=(prevProps, prevState)=>{
        if(this.state.instansi.created_by!==user_id()){
            if(get_instansi_selected().id_instansi!==""){
                this.props.history.goBack()
            }
        }
    }

    //get data
    getSelectedInstansi=()=>{
        if(get_instansi_selected().id_instansi!==""){
            this.setState({
                instansi:get_instansi_selected(),
                active_tab:2
            }, ()=>{
                this.getOrganization()
            })
        }
    }
    getOrganization=()=>{
        API.post("/dashboard/jabatan/get_organization", JSON.stringify({
            token:access_token(),
            id_instansi:this.state.instansi.id_instansi
        }))
        .then(res=>{
            this.setState({
                jabatan:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }
    getSearchPekerja=q=>{
        API.post("/dashboard/users/search_pekerja", JSON.stringify({
            token:access_token(),
            search:q
        }))
        .then(res=>{
            this.setState({
                tambah_pekerja:update(this.state.tambah_pekerja, {
                    results:{$set:res.data.data},
                    is_typing:{$set:false}
                })
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }

    //tambah instansi
    typeTambahInstansi=e=>{
        const target=e.target

        this.setState({
            tambah_instansi:update(this.state.tambah_instansi, {
                [target.name]:{$set:target.value}
            })
        })
    }
    tambahInstansi=e=>{
        const context=this.context
        e.preventDefault()

        this.setState({is_loading:true})
        API.post("/dashboard/instansi/add_instansi", JSON.stringify({
            token:access_token(),
            nama_instansi:this.state.tambah_instansi.nama_instansi
        }))
        .then(res=>{
            this.setState({
                instansi:res.data.instansi,
                is_loading:false,
                active_tab:2
            }, ()=>{
                this.getOrganization()
                context.getListInstansi()
            })
            localStorage.setItem("instansi_selected", JSON.stringify(res.data.instansi))
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
                case 500:
                    this.setState({
                        form_validation_tambah_instansi:err.response.data.status.data
                    })
                break;
            }
            this.setState({is_loading:false})
        })
    }

    //tambah jabatan
    showTambahJabatan=id_jabatan=>{
        this.setState({
            tambah_jabatan:{
                is_open:true,
                id_instansi:this.state.instansi.id_instansi,
                bawahan_dari:id_jabatan,
                nama_jabatan:"",
                jabatan_type:"atasan"
            }
        })
    }
    hideTambahJabatan=()=>{
        this.setState({
            tambah_jabatan:{
                is_open:false,
                id_instansi:"",
                bawahan_dari:"",
                nama_jabatan:"",
                jabatan_type:"atasan"
            }
        })
    }
    typeJabatan=e=>{
        const target=e.target

        this.setState({
            tambah_jabatan:update(this.state.tambah_jabatan, {
                [target.name]:{$set:target.value}
            })
        })
    }
    getRoleChecked=val=>{
        if(val===this.state.tambah_jabatan.jabatan_type){
            return true
        }
        else{
            return false
        }
    }
    tambahJabatan=e=>{
        e.preventDefault()

        const {instansi, tambah_jabatan}=this.state

        this.setState({is_loading:true})
        API.post("/dashboard/jabatan/add_jabatan", JSON.stringify({
            token:access_token(),
            id_instansi:instansi.id_instansi,
            nama_jabatan:tambah_jabatan.nama_jabatan,
            bawahan_dari:tambah_jabatan.bawahan_dari,
            jabatan_type:tambah_jabatan.jabatan_type
        }))
        .then(res=>{
            this.getOrganization()
            this.hideTambahJabatan()
            this.setState({is_loading:false})
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
                case 500:
                    this.setState({
                        form_validation_tambah_jabatan:err.response.data.status.data
                    })
                break;
            }
            this.setState({is_loading:false})
        })
    }

    //edit jabatan
    showEditJabatan=data=>{
        this.setState({
            edit_jabatan:{
                is_open:true,
                id_jabatan:data.id_jabatan,
                nama_jabatan:data.nama_jabatan
            }
        })
    }
    hideEditJabatan=()=>{
        this.setState({
            edit_jabatan:{
                is_open:false,
                id_jabatan:"",
                nama_jabatan:""
            }
        })
    }
    typeEditJabatan=(e)=>{
        const target=e.target

        this.setState({
            edit_jabatan:update(this.state.edit_jabatan, {
                [target.name]:{$set:target.value}
            })
        })
    }
    updateJabatan=e=>{
        e.preventDefault()

        const {edit_jabatan}=this.state

        this.setState({is_loading:true})
        API.post("/dashboard/jabatan/update_jabatan", JSON.stringify({
            token:access_token(),
            id_jabatan:edit_jabatan.id_jabatan,
            nama_jabatan:edit_jabatan.nama_jabatan
        }))
        .then(res=>{
            this.getOrganization()
            this.hideEditJabatan()
            this.setState({is_loading:false})
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
                case 500:
                    this.setState({
                        form_validation_edit_jabatan:err.response.data.status.data
                    })
                break;
            }
            this.setState({is_loading:false})
        })
    }

    //hapus jabatan
    showHapusJabatan=id_jabatan=>{
        this.setState({
            hapus_jabatan:{
                is_open:true,
                id_jabatan:id_jabatan
            }
        })
    }
    hideHapusJabatan=()=>{
        this.setState({
            hapus_jabatan:{
                is_open:false,
                id_jabatan:""
            }
        })
    }
    hapusJabatan=e=>{
        e.preventDefault()
        API.post("/dashboard/jabatan/delete_jabatan", JSON.stringify({
            token:access_token(),
            id_jabatan:this.state.hapus_jabatan.id_jabatan
        }))
        .then(res=>{
            this.getOrganization()
            this.hideHapusJabatan()
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }

    //tambah pekerja
    showTambahPekerja=id_jabatan=>{
        this.setState({
            tambah_pekerja:{
                is_open:true,
                id_jabatan:id_jabatan,
                email:""
            }
        })
    }
    hideTambahPekerja=()=>{
        this.setState({
            tambah_pekerja:{
                is_open:false,
                id_jabatan:"",
                email:""
            }
        })
    }
    typePekerja=e=>{
        const target=e.target

        this.setState({
            tambah_pekerja:update(this.state.tambah_pekerja, {
                [target.name]:{$set:target.value}
            })
        })
    }
    tambahPekerja=e=>{
        e.preventDefault()

        const {tambah_pekerja}=this.state

        this.setState({is_loading:true})
        API.post("/dashboard/jabatan_users/add_jabatan_user", JSON.stringify({
            token:access_token(),
            id_jabatan:tambah_pekerja.id_jabatan,
            email:tambah_pekerja.email
        }))
        .then(res=>{
            this.getOrganization()
            this.hideTambahPekerja()
            this.setState({is_loading:false})
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
                case 500:
                    this.setState({
                        form_validation_tambah_pekerja:err.response.data.status.data
                    })
                break;
                case 403:
                    toast.warn("User tidak Ditemukan, Pastikan E-Mail sudah benar dan User yang Terkait sudah daftar!")
                break;
            }
            this.setState({is_loading:false})
        })
    }

    //hapus pekerja
    showHapusPekerja=id_jabatan_user=>{
        this.setState({
            hapus_pekerja:{
                is_open:true,
                id_jabatan_user:id_jabatan_user
            }
        })
    }
    hideHapusPekerja=()=>{
        this.setState({
            hapus_pekerja:{
                is_open:false,
                id_jabatan_user:""
            }
        })
    }
    hapusPekerja=e=>{
        e.preventDefault()

        API.post("/dashboard/jabatan_users/delete_jabatan_user", JSON.stringify({
            token:access_token(),
            id_jabatan_user:this.state.hapus_pekerja.id_jabatan_user
        }))
        .then(res=>{
            this.getOrganization()
            this.hideHapusPekerja()
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }

    //tabs
    setActiveTab=tab=>{
        this.setState({
            active_tab:tab
        })
    }

    //context
    jabatanContextValue=()=>{
        return {
            showTambahJabatan:this.showTambahJabatan,
            showEditJabatan:this.showEditJabatan,
            showHapusJabatan:this.showHapusJabatan,
            showTambahPekerja:this.showTambahPekerja,
            showHapusPekerja:this.showHapusPekerja
        }
    }

    render() {
        const {active_tab, tambah_instansi, form_validation_tambah_instansi, jabatan, tambah_jabatan, form_validation_tambah_jabatan, edit_jabatan, form_validation_edit_jabatan, tambah_pekerja, hapus_jabatan, hapus_pekerja, is_loading}=this.state

        return (
            <>
                <div className="content mt-4 mb-5 mt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <ol className="step-wizard mt-5">
                                    <li class={classNames({"current":active_tab>=1})} style={{width:"33%"}}>
                                        <div class="step-icon"><BiBuildingHouse className="fs-5"/></div>
                                        <span class="step-title fs-7 fw-bold">Buat Instansi Anda</span>
                                        <p className="step-description text-muted d-none d-md-block">Tambahkan Instansi tempat anda bekerja.</p>
                                    </li>
                                    <li class={classNames({"current":active_tab>=2})} style={{width:"50%"}}>
                                        <div class="step-icon"><BiUserPlus className="fs-5"/></div>
                                        <span class="step-title fs-7 fw-bold">Cari Pekerja & Buat Jabatan</span>
                                        <p className="step-description text-muted d-none d-md-block">Cari pegawai/karyawan anda(harus daftar) dan Buat jabatan untuk pegawai/karyawan anda.</p>
                                    </li>
                                    <li class={classNames({"current":active_tab>=3})} style={{width:"17%"}}>
                                        <div class="step-icon"><BiCheck className="fs-5"/></div>
                                        <span class="step-title fs-7 fw-bold">Selesai</span>
                                    </li>
                                </ol>
                            </div>
                            <div className="col-md-10 mx-auto">
                                <SwitchTransition mode="out-in">
                                    <CSSTransition
                                        key={active_tab}
                                        addEndListener={(node, done)=>{
                                            node.addEventListener("transitionend", done, false)
                                        }}
                                        classNames="tg-zoom"
                                        timeout={300}
                                    >
                                        <>
                                            {active_tab===1&&
                                                <form className="card card-form col-md-12 mx-auto mt-4" onSubmit={this.tambahInstansi} key="1">
                                                    <div className="card-body rounded-bottom-0">
                                                        <div className="row mt-3">
                                                            <div className="col-md-8 mx-auto">
                                                                <div className="row align-items-center mb-3">
                                                                    <label 
                                                                        for="nama_instansi" 
                                                                        className="col-sm-4 col-form-label fw-semibold text-start text-md-end"
                                                                    >
                                                                        Nama Instansi
                                                                    </label>
                                                                    <div className="col-sm-8">
                                                                        <OverlayTrigger
                                                                            trigger="focus"
                                                                            placement="right"
                                                                            overlay={
                                                                                !isUndefined(form_validation_tambah_instansi.nama_instansi)?
                                                                                <Popover id="popover-username" className="popover-danger">
                                                                                    <Popover.Body>
                                                                                        <span class="text-white">Nama Instansi Harus diisi!</span>
                                                                                    </Popover.Body>
                                                                                </Popover>
                                                                                :<></>
                                                                            }
                                                                        >
                                                                            <input 
                                                                                type="text" 
                                                                                className={classNames("form-control", {"is-invalid":!isUndefined(form_validation_tambah_instansi.nama_instansi)})}
                                                                                id="nama_instansi" 
                                                                                placeholder="Nama Instansi"
                                                                                onChange={this.typeTambahInstansi}
                                                                                name="nama_instansi"
                                                                                value={tambah_instansi.nama_instansi}
                                                                            />
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer">
                                                        <button 
                                                            type="submit"
                                                            className="btn btn-primary float-end"
                                                            disabled={is_loading}
                                                        >
                                                            Simpan & Lanjutkan
                                                        </button>
                                                    </div>
                                                </form>
                                            }
                                            {active_tab===2&&
                                                <div className="card card-form mt-4" key="2">
                                                    <div className="card-body rounded-bottom-0 hide-scrollbar p-3" style={{overflowX:"auto", minHeight:"200px"}}>
                                                        <JabatanContext.Provider value={this.jabatanContextValue()}>
                                                            <OrganizationChart
                                                                data={jabatan}
                                                            />
                                                        </JabatanContext.Provider>
                                                    </div>
                                                    <div className="card-footer">
                                                        <button 
                                                            type="button" 
                                                            className="btn btn-primary float-end"
                                                            onClick={()=>this.setActiveTab(3)}
                                                        >
                                                            Lanjutkan Step
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                            {active_tab===3&&
                                                <div className="card card-form mt-4" key="3">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-md-8 mx-auto">
                                                                <div className="d-flex flex-column align-items-center">
                                                                    <div style={{fontSize:"70px"}} className="text-success"><AiFillCheckCircle/></div>
                                                                    <p className="text-center">Kamu telah menyelesaikan tahapan pembuatan Instansi di Garapan.id, kamu juga dapat mengedit instansi jika diperlukan!</p>
                                                                    <Link to="/" className="btn btn-primary">ke Dashboard</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    </CSSTransition>
                                </SwitchTransition>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MODAL TAMBAH JABATAN */}
                <Modal show={tambah_jabatan.is_open} backdrop="static" onHide={this.hideTambahJabatan}>
                    <form onSubmit={this.tambahJabatan}>
                        <Modal.Header closeButton>
                            <Modal.Title className="fw-bold fs-5">Tambah Jabatan</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    !isUndefined(form_validation_tambah_jabatan.jabatan_type)?
                                    <Popover id="popover-ddf" className="popover-danger">
                                        <Popover.Body>
                                            <span class="text-white">Tipe Jabatan Harus diisi!</span>
                                        </Popover.Body>
                                    </Popover>
                                    :<></>
                                }
                            >
                                <div className="row g-3">
                                    <div className="col-sm-6">
                                        <CustomRadioPicker
                                            label="Role Atasan"
                                            info="Dapat Menerima dan Mengirim Tugas!"
                                            checked={this.getRoleChecked("atasan")}
                                            name="jabatan_type"
                                            value="atasan"
                                            isInvalid={ !isUndefined(form_validation_tambah_jabatan.jabatan_type)?true:false}
                                            onChange={this.typeJabatan}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <CustomRadioPicker
                                            label="Role Bawahan"
                                            info="Hanya bisa Menerima Tugas!"
                                            checked={this.getRoleChecked("bawahan")}
                                            name="jabatan_type"
                                            value="bawahan"
                                            isInvalid={ !isUndefined(form_validation_tambah_jabatan.jabatan_type)?true:false}
                                            onChange={this.typeJabatan}
                                        />
                                    </div>
                                </div>
                            </OverlayTrigger>
                            <div class="row mt-3">
                                <div className="col-12">
                                    <label for="nama_jabatan" class="form-label fw-semibold">Nama Jabatan</label>
                                    <OverlayTrigger
                                        trigger="focus"
                                        placement="right"
                                        overlay={
                                            !isUndefined(form_validation_tambah_jabatan.nama_jabatan)?
                                            <Popover id="popover-ddf" className="popover-danger">
                                                <Popover.Body>
                                                    <span class="text-white">Nama Jabatan Harus diisi!</span>
                                                </Popover.Body>
                                            </Popover>
                                            :<></>
                                        }
                                    >
                                        <input 
                                            type="text" 
                                            class={classNames("form-control", {"is-invalid":!isUndefined(form_validation_tambah_jabatan.nama_jabatan)})}
                                            id="nama_jabatan"
                                            name="nama_jabatan"
                                            value={tambah_jabatan.nama_jabatan}
                                            onChange={this.typeJabatan}
                                        />
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-between">
                            <button type="button" className="btn btn-light fw-semibold" onClick={this.hideTambahJabatan}>
                                Close
                            </button>
                            <button className="btn btn-primary fw-semibold" type="submit" disabled={is_loading}>
                                <FiCheck className="fs-6 me-1"/>
                                Simpan Data
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL EDIT JABATAN */}
                <Modal show={edit_jabatan.is_open} backdrop="static" onHide={this.hideEditJabatan}>
                    <form onSubmit={this.updateJabatan}>
                        <Modal.Header closeButton>
                            <Modal.Title className="fw-bold fs-5">Edit Jabatan</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row">
                                <div className="col-12">
                                    <label for="edit_nama_jabatan" class="form-label fw-semibold">Nama Jabatan</label>
                                    <OverlayTrigger
                                        trigger="focus"
                                        placement="right"
                                        overlay={
                                            !isUndefined(form_validation_edit_jabatan.nama_jabatan)?
                                            <Popover id="popover-ddf" className="popover-danger">
                                                <Popover.Body>
                                                    <span class="text-white">Nama Jabatan Harus diisi!</span>
                                                </Popover.Body>
                                            </Popover>
                                            :<></>
                                        }
                                    >
                                        <input 
                                            type="text" 
                                            class={classNames("form-control", {"is-invalid":!isUndefined(form_validation_edit_jabatan.nama_jabatan)})}
                                            id="edit_nama_jabatan"
                                            name="nama_jabatan"
                                            value={edit_jabatan.nama_jabatan}
                                            onChange={this.typeEditJabatan}
                                        />
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-between">
                            <button type="button" className="btn btn-light fw-semibold" onClick={this.hideEditJabatan}>
                                Close
                            </button>
                            <button className="btn btn-primary fw-semibold" type="submit" disabled={is_loading}>
                                <FiCheck className="fs-6 me-1"/>
                                Simpan Data
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL HAPUS JABATAN */}
                <ConfirmDelete
                    show={hapus_jabatan.is_open}
                    toggle={this.hideHapusJabatan}
                    title="Yakin ingin Dihapus"
                    sub_title="Data Jabatan yang sudah dihapus tidak bisa dikembalikan lagi!"
                    deleteAction={this.hapusJabatan}
                />

                {/* MODAL TAMBAH PEKERJA */}
                <Modal show={tambah_pekerja.is_open} backdrop="static" onHide={this.hideTambahPekerja} dialogClassName="modal-smd">
                    <form onSubmit={this.tambahPekerja}>
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold fs-5">Tambah Pekerja</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="E-Mail Pengguna"
                                name="email"
                                value={tambah_pekerja.email}
                                onChange={this.typePekerja}
                            />
                        </div>
                        <div className="form-text mt-2">Masukkan Email Pekerja/Karyawan dan Undang ke Jabatan yang Dipilih!</div>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between">
                        <button type="button" className="btn btn-light fw-semibold" onClick={this.hideTambahPekerja}>
                            Close
                        </button>
                        <button className="btn btn-primary fw-semibold" type="submit" disabled={is_loading}>
                            Undang Pekerja
                        </button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* MODAL HAPUS PEKERJA */}
                <ConfirmDelete
                    show={hapus_pekerja.is_open}
                    toggle={this.hideHapusPekerja}
                    title="Yakin ingin Dihapus"
                    sub_title="Data Pekerja yang sudah dihapus tidak bisa dikembalikan lagi!"
                    deleteAction={this.hapusPekerja}
                />
            </>
        )
    }
}
StartWizard.contextType=RouterContext

export default withAuth(StartWizard)