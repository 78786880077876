import React, { Component } from 'react'
import qs from "querystring"
import update from "immutability-helper"
import { BiArrowToTop, BiCommentDetail, BiDownArrow, BiSend, BiUpArrow, BiUpArrowAlt, BiUpload } from 'react-icons/bi'
import {FiTrash2} from "react-icons/fi"
import { VscFilePdf } from 'react-icons/vsc'
import withAuth from '../../../component/hoc/auth'
import Dropzone from 'react-dropzone'
import { Accordion, Button, Modal } from 'react-bootstrap'
import RangeSlider from '../../../component/ui/range_slider'
import { API } from '../../../config/api'
import { access_token, BASE_URL, get_instansi_selected, isUndefined, remove_login_data, login_data, user_id } from '../../../config/config'
import Avatar from '../../../component/ui/avatar'
import TextareaAutosize from "react-textarea-autosize"
import { UnmountClosed } from 'react-collapse'
import { messaging, vapid_key } from '../../../config/firebase'
import { getToken, onMessage, getMessaging } from "firebase/messaging"
import DocumentIcon from '../../../component/ui/documen_icon'
import { CSSTransition } from 'react-transition-group'
import RouterContext from '../../../store/router_context'
import { Confirm } from '../../../component/ui/confirm'
import { Mention, MentionsInput } from 'react-mentions'
import { pusher } from '../../../config/pusher'

class DetailTugas extends Component {
    state={
        tugas:{},
        riwayat:[],
        users:[],
        tambah_activity:{
            is_open:false,
            status:"progress",
            progress:0,
            skor:0,
            dokumen:[]
        },
        upload_progress:{
            is_show:false,
            progress:0
        },
        fv_tambah_activity:{},
        activity_active:"",
        komentar:[],
        komentar_offset:0,
        komentar_limit:5,
        komentar_found_previous:false,
        tambah_komentar:{
            komentar:""
        },
        fv_tambah_komentar:{},
        hapus_riwayat:{
            is_open:false,
            id_tugas_activity:""
        },
        is_loading:false
    }

    componentDidMount=()=>{
        this.getTugas()
        this.getListActivity()
        this.getUsersMentions()

        const context=this.context

        const private_channel=pusher(access_token()).subscribe("private-user_"+user_id())
        private_channel.bind("notif", data=>{
            if(data.type==="komentar"){
                this.setRealtimeKomentar(data.data)
                this.setUnreadKomentar(data.data.id_tugas_activity)
            }
        })
    }
    componentDidUpdate=(prevProps)=>{
        if(prevProps.match.params.id_tugas!==this.props.match.params.id_tugas){
            this.getTugas()
            this.getListActivity()
        }
    }
    getTugas=()=>{
        API.post("/dashboard/tugas/get_tugas", JSON.stringify({
            token:access_token(),
            id_tugas:this.props.match.params.id_tugas
        }))
        .then(res=>{
            this.setState({
                tugas:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()
                break;
            }
        })
    }
    getListActivity=()=>{
        API.post("/dashboard/activity/list_activity", JSON.stringify({
            token:access_token(),
            id_tugas:this.props.match.params.id_tugas
        }))
        .then(res=>{
            this.setState({
                riwayat:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()
                break;
            }
        })
    }
    getListKomentar=(id_tugas_activity)=>{
        let offset_komen
        if(id_tugas_activity===this.state.activity_active) offset_komen=this.state.komentar_offset
        else offset_komen=0
        
        API.post("/dashboard/komentar/list_komentar", JSON.stringify({
            token:access_token(),
            id_tugas_activity:id_tugas_activity,
            filter:{
                offset:offset_komen,
                limit:this.state.komentar_limit
            }
        }))
        .then(res=>{
            this.setState({
                komentar:res.data.data.concat(this.state.komentar),
                komentar_offset:offset_komen+this.state.komentar_limit,
                activity_active:id_tugas_activity,
                komentar_found_previous:res.data.komentar_found_previous
            })
            this.readKomentar(id_tugas_activity)
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()
                break;
            }
        })
    }
    getUsersMentions=()=>{
        API.post("/dashboard/users/get_users_mention", JSON.stringify({
            token:access_token(),
            id_tugas:this.props.match.params.id_tugas
        }))
        .then(res=>{
            this.setState({
                users:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()
                break;
            }
        })
    }
    getStatusTugas=status=>{
        switch(status){
            case "progress":
                return <span className="badge bg-azure">Sedang Dikerjakan</span>
            break;
            case "problem":
                return <span className="badge bg-red">Ada Masalah</span>
            break;
            case "delivery":
                return <span className="badge bg-purple">Mohon Dicek</span>
            break;
            case "complete":
                return <span className="badge bg-green">Selesai</span>
            break;
            case "continue":
                return <span  className="badge bg-blue">Lanjutkan</span>
            break;
        }
    }
    setRealtimeKomentar=(data)=>{
        if(data.id_tugas_activity===this.state.activity_active){
            this.setState({
                komentar:this.state.komentar.concat([data]),
                komentar_offset:this.state.komentar_offset+1
            })
        }
        
    }
    setUnreadKomentar=(id_tugas_activity)=>{
        const riwayat=this.state.riwayat.map(rw=>{
            if(rw.id_tugas_activity===id_tugas_activity){
                return Object.assign(rw, {
                    unread_komentar:true
                })
            }
            else{
                return rw
            }
        })

        this.setState({
            riwayat:riwayat
        }, ()=>{
            if(id_tugas_activity===this.state.activity_active){
                this.readKomentar(id_tugas_activity)
            }
        })
    }
    readKomentar=(id_tugas_activity)=>{
        API.post("/dashboard/komentar/read_komentar", JSON.stringify({
            token:access_token(),
            id_tugas_activity:id_tugas_activity
        }))
        .then(res=>{
            const riwayat=this.state.riwayat.map(rw=>{
                if(rw.id_tugas_activity===id_tugas_activity){
                    return Object.assign(rw, {
                        unread_komentar:false
                    })
                }
                else{
                    return rw
                }
            })
    
            this.setState({
                riwayat:riwayat
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()
                break;
            }
        })
    }

    //submit tugas
    showSubmitTugas=(e)=>{
        e.preventDefault()

        this.setState({
            tambah_activity:update(this.state.tambah_activity, {
                is_open:{$set:true}
            })
        })
    }
    hideSubmitTugas=()=>{
        this.setState({
            tambah_activity:update(this.state.tambah_activity, {
                is_open:{$set:false},
                status:{$set:"progress"},
                progress:{$set:0},
                skor:{$set:0},
                dokumen:{$set:[]}
            })
        })
    }
    typeSubmitTugas=e=>{
        const target=e.target

        this.setState({
            tambah_activity:update(this.state.tambah_activity, {
                [target.name]:{$set:target.value}
            })
        })
    }
    typeProgress=value=>{
        this.setState({
            tambah_activity:update(this.state.tambah_activity, {
                progress:{$set:value}
            })
        })
    }
    typeSkor=value=>{
        this.setState({
            tambah_activity:update(this.state.tambah_activity, {
                skor:{$set:value}
            })
        })
    }
    submitTugas=(e)=>{
        e.preventDefault()

        const {tambah_activity}=this.state

        this.setState({is_loading:true})
        API.post("/dashboard/activity/add_activity", JSON.stringify({
            token:access_token(),
            id_tugas:this.props.match.params.id_tugas,
            status:tambah_activity.status,
            progress:tambah_activity.progress,
            skor:tambah_activity.skor,
            dokumen:tambah_activity.dokumen
        }))
        .then(res=>{
            this.getListActivity()
            this.hideSubmitTugas()
            this.setState({
                is_loading:false
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
                case 500:
                    this.setState({
                        fv_tambah_activity:err.response.data.status.data
                    })
                break;
            }
            this.setState({is_loading:false})
        })
    }

    //upload file
    progressFile=(progress)=>{
        this.setState(prevState=>{
            return {
                ...prevState,
                upload_progress:{
                    ...prevState.upload_progress,
                    is_show:true,
                    progress:progress
                }
            }
        })
    }
    closeProgressFile=()=>{
        this.setState(prevState=>{
            return {
                ...prevState,
                upload_progress:{
                    ...prevState.upload_progress,
                    is_show:false,
                    progress:0
                }
            }
        })
    }
    uploadFiles=(files)=>{
        const form_data=new FormData()

        for(var i=0;i<files.length;i++){
            form_data.append("dokumen["+i+"]", files[i])
        }
        form_data.append("token", access_token())

        API.post("/dashboard/file_manager/upload_file", form_data, {
            headers:{
                'content-type':'multipart/form-data'
            },
            onUploadProgress:data=>{
                const progress=Math.round((100 * data.loaded) / data.total)
                this.progressFile(progress)
            }
        })
        .then(res=>{
            const dokumen=this.state.tambah_activity.dokumen.concat(res.data.dokumen)
            this.setState({
                tambah_activity:update(this.state.tambah_activity, {
                    dokumen:{$set:dokumen}
                })
            })
            this.closeProgressFile()
        })
        .catch(err=>{
            if(err.response.status===401){
               remove_login_data()
            }
        })
    }
    removeFile=(doc)=>{
        API.post("/dashboard/file_manager/remove_file", qs.stringify({
            token:access_token(),
            file_name:doc.path
        }))
        .then(res=>{
            const dokumen=this.state.tambah_activity.dokumen.filter(dok=>dok!==doc)

            this.setState({
                tambah_activity:update(this.state.tambah_activity, {
                    dokumen:{$set:dokumen}
                })
            })
        })
        .catch(err=>{
            if(err.response.status===401){
                remove_login_data()
            }
        })
    }

    //komentar
    toggleKomentar=(activity_id)=>{
        let activity
        if(activity_id===this.state.activity_active) activity=""
        else activity=activity_id

        this.setState({
            activity_active:"",
            tambah_komentar:{
                komentar:""
            },
            komentar:[],
            komentar_offset:0
        }, ()=>{
            if(activity===""){
                this.setState({
                    activity_active:"",
                    komentar:[]
                })
            }
            else{
                this.getListKomentar(activity)
            }
        })
    }
    typeKomentar=(e)=>{
        const target=e.target

        this.setState({
            tambah_komentar:{
                komentar:target.value
            }
        })
    }
    tambahKomentar=(e)=>{
        e.preventDefault()

        const {tambah_komentar, activity_active}=this.state

        API.post("/dashboard/komentar/add_komentar", JSON.stringify({
            token:access_token(),
            id_tugas_activity:activity_active,
            komentar:tambah_komentar.komentar
        }))
        .then(res=>{
            this.setState({
                tambah_komentar:{
                    komentar:""
                },
                komentar:this.state.komentar.concat([res.data.data]),
                komentar_offset:this.state.komentar_offset+1
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
                case 500:
                    this.setState({
                        fv_tambah_komentar:err.response.data.status.data
                    })
                break;
            }
        })
    }

    //hapus riwayat tugas
    showHapusRiwayatTugas=id_tugas_activity=>{
        this.setState({
            hapus_riwayat:{
                is_open:true,
                id_tugas_activity:id_tugas_activity
            }
        })
    }
    hideHapusRiwayatTugas=()=>{
        this.setState({
            hapus_riwayat:{
                is_open:false,
                id_tugas_activity:""
            }
        })
    }
    hapusRiwayat=()=>{
        API.post("/dashboard/activity/delete_activity", JSON.stringify({
            token:access_token(),
            id_tugas:this.props.match.params.id_tugas,
            id_tugas_activity:this.state.hapus_riwayat.id_tugas_activity
        }))
        .then(res=>{
            this.getTugas()
            this.getListActivity()
            this.hideHapusRiwayatTugas()
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()
                break;
            }
        })
    }

    //refs
    commentRef=React.createRef()
    getCommentWidth=()=>{
        const r=this.commentRef.current
        if(r===null){
            return "auto"
        }
        else{
            return Math.floor(r.getBoundingClientRect().width)
        }
    }

    render() {
        const {submit_tugas, tugas, riwayat, komentar, tambah_activity, users, fv_tambah_activity, tambah_komentar, activity_active, is_loading, komentar_found_previous, upload_progress, hapus_riwayat}=this.state

        return (
            <>
                <div className="content mt-4 mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div class="card widget-detail-tugas">
                                            <div class="card-header d-flex justify-content-between">
                                                <h5 className="card-title text-truncate">Detail Tugas</h5>
                                            </div>
                                            <div class="card-body p-4 mb-4">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="d-flex flex-column mb-4">
                                                            <span className="text-reset">Aplikasi</span>
                                                            <h2 className="fs-3 fw-bold">{tugas.nama_tugas}</h2>
                                                            {tugas.prioritas&&
                                                                <span className="badge bg-warning align-self-start">Penting</span>
                                                            }
                                                        </div>
                                                        <div className="d-flex flex-column mb-4">
                                                            <span className="text-muted">Tugas Dari</span>
                                                            <div className="d-flex align-items-center mt-2">
                                                                <span className="avatar avatar-xmd">
                                                                    <Avatar data={tugas.pengirim} circle/>
                                                                </span>
                                                                <div className="mx-3">
                                                                    <h4 className="fs-7 fw-bold mb-0">
                                                                        {!isUndefined(tugas.pengirim)&&
                                                                            <span className="text-dark fw-bold">{tugas.pengirim.nama_lengkap}</span>
                                                                        }
                                                                    </h4>
                                                                    <span className="text-muted fs-8">
                                                                        {!isUndefined(tugas.pengirim)&&
                                                                            <>{tugas.pengirim.jabatan}</>
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column mb-4">
                                                            <span className="text-muted">Ditugaskan Kepada</span>
                                                            <div className="d-flex align-items-center mt-2">
                                                                <span className="avatar avatar-xmd">
                                                                    <Avatar data={tugas.penerima} circle/>
                                                                </span>
                                                                <div className="mx-3">
                                                                    <h4 className="fs-7 fw-bold mb-0">
                                                                        {!isUndefined(tugas.penerima)&&
                                                                            <span className="text-dark fw-bold">{tugas.penerima.nama_lengkap}</span>
                                                                        }
                                                                    </h4>
                                                                    <span className="text-muted fs-8">
                                                                        {!isUndefined(tugas.penerima)&&
                                                                            <>{tugas.penerima.jabatan}</>
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column mb-4">
                                                            <span className="text-muted">Deadline</span>
                                                            <span class="badge bg-primary align-self-start mt-1 px-3">{tugas.deadline}</span>
                                                        </div>
                                                        <div className="d-flex flex-column mb-4">
                                                            <span className="text-muted">File Tugas</span>
                                                            {!isUndefined(tugas.dokumen)&&
                                                                <div className=" py-2">
                                                                    {tugas.dokumen.map(doc=>(
                                                                        <div className="d-flex justify-content-between mt-1 align-items-center">
                                                                            <a href={BASE_URL+"/"+doc.path} target="_blank" className="text-truncate d-flex align-items-center">
                                                                                <DocumentIcon
                                                                                    data={doc.path}
                                                                                />
                                                                                <span className="ms-2">{doc.file_name}</span>
                                                                            </a>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="d-flex flex-column mb-4">
                                                            <div className="d-flex justify-content-between">
                                                                <span className="text-muted fs-7">Progress</span>
                                                                <span>{tugas.progress}%</span>
                                                            </div>
                                                            <div className="w-100 mt-1">
                                                                <div class="progress" style={{height:"4px"}}>
                                                                    <div class="progress-bar" role="progressbar" style={{width:tugas.progress+"%"}}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column mb-4">
                                                            <span className="text-muted">Keterangan</span>
                                                            <p>{tugas.deskripsi}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 d-flex">
                                                        {!isUndefined(tugas.pengirim)&&
                                                        <>
                                                            {[tugas.pengirim.id_user, tugas.penerima.id_user].includes(user_id())&&
                                                                <button 
                                                                    className="btn btn-primary py-3 px-4 align-self-start fw-bold mx-auto me-md-0" 
                                                                    onClick={this.showSubmitTugas}
                                                                >
                                                                    {tugas.pengirim.id_user===user_id()?"Update Status":"Submit Tugas"}
                                                                </button>
                                                            }
                                                        </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card widget-riwayat-tugas">
                                            <div class="card-header d-flex justify-content-between">
                                                <h5 className="card-title text-truncate">Riwayat Tugas</h5>
                                            </div>
                                            <div class="card-body p-4 mb-4">
                                                {riwayat.map(act=>(
                                                    <>
                                                        <div>
                                                            <div className="row mt-3">
                                                                <div className="col-12">
                                                                    <div className="row align-items-center border-bottom ">
                                                                        <div className="col-9 col-md-12 py-2">
                                                                            <div className="row align-items-center">
                                                                                <div className="col-md-3">
                                                                                    <span className="text-muted">{act.created_at}</span>
                                                                                </div>
                                                                                <div className="col-md-5 mt-2 mt-md-0">
                                                                                    <div className="d-flex flex-column">
                                                                                        {act.dokumen.map(doc=>(
                                                                                            <a 
                                                                                                href={BASE_URL+"/"+doc.path} 
                                                                                                target="_blank"
                                                                                                className="ms-2 link-primary text-decoration-underline d-flex align-items-center"
                                                                                            >
                                                                                                <DocumentIcon
                                                                                                    data={doc.path}
                                                                                                />
                                                                                                <span className="ms-2">{doc.file_name}</span>
                                                                                            </a>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-2 mt-2 mt-md-0">
                                                                                    Status : <br/>{this.getStatusTugas(act.status)}
                                                                                </div>
                                                                                <div className="col-md-1 mt-2 mt-md-0">
                                                                                    <div 
                                                                                        className="d-flex flex-column align-items-center d-none d-md-flex cursor-pointer position-relative"
                                                                                        onClick={(e)=>this.toggleKomentar(act.id_tugas_activity)}
                                                                                    >
                                                                                        <div className="position-relative">
                                                                                            <BiCommentDetail className="fs-4"/>
                                                                                            {act.unread_komentar&&
                                                                                                <span className="notification-dot notification-komentar"></span>
                                                                                            }
                                                                                        </div>
                                                                                        <span className="text-muted fs-9">Komentar</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-1 mt-2 mt-md-0 d-none d-md-flex justify-content-end ">
                                                                                    {user_id()===act.id_user&&
                                                                                        <button className="btn btn-danger btn-sm" title="Hapus" onClick={()=>this.showHapusRiwayatTugas(act.id_tugas_activity)}><FiTrash2/></button>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-3 d-md-none">
                                                                            <div className="d-flex flex-column align-items-center">
                                                                                <div
                                                                                    className="d-flex flex-column align-items-center cursor-pointer position-relative"
                                                                                    onClick={(e)=>this.toggleKomentar(act.id_tugas_activity)}
                                                                                >
                                                                                    <div className="position-relative">
                                                                                        <BiCommentDetail className="fs-4"/>
                                                                                        {act.unread_komentar&&
                                                                                            <span className="notification-dot notification-komentar"></span>
                                                                                        }
                                                                                    </div>
                                                                                    <span className="text-muted fs-9">Komentar</span>
                                                                                </div>
                                                                                {user_id()===act.id_user&&
                                                                                    <button className="btn btn-danger btn-sm mt-2" title="Hapus" onClick={()=>this.showHapusRiwayatTugas(act.id_tugas_activity)}><FiTrash2/></button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <UnmountClosed isOpened={activity_active===act.id_tugas_activity} className="col-12">
                                                                <div className="row border-bottom">
                                                                    <div className="col-md-8 offset-md-3 mb-3">
                                                                        <h5 className="fs-6 fw-bold mt-3">Komentar</h5>
                                                                        <div className="mt-3">
                                                                            {komentar_found_previous&&
                                                                                <div>
                                                                                    <button 
                                                                                        className="btn btn-link link-primary px-0"
                                                                                        onClick={e=>this.getListKomentar(act.id_tugas_activity)}
                                                                                    >
                                                                                        Komentar Sebelumnya <BiUpArrowAlt/>
                                                                                    </button>
                                                                                </div>
                                                                            }
                                                                            <div class="list-group comment-list">
                                                                                {komentar.map(komen=>(
                                                                                    <div class="list-group-item d-flex mb-3 px-0 border-0">
                                                                                        <div className="me-3">
                                                                                            <span className="avatar avatar-xmd">
                                                                                                <Avatar data={komen.pengirim} circle/>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="comment-wrapper p-3">
                                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                                <h6 className="fs-7 fw-bold mb-0">{komen.pengirim.nama_lengkap}</h6>
                                                                                                <span className="text-muted fs-8 ms-3">{komen.created_at}</span>
                                                                                            </div>
                                                                                            <div className="mb-0 mt-2 pre-wrap" dangerouslySetInnerHTML={{__html:komen.komentar}}/>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                            <div className="d-flex mt-3">
                                                                                <div className="me-3">
                                                                                    <span className="avatar avatar-xmd">
                                                                                        <Avatar 
                                                                                            data={{avatar_url:login_data().avatar_url, nama_2:login_data().nama_2}}
                                                                                            circle
                                                                                        />
                                                                                    </span>
                                                                                </div>
                                                                                <form ref={this.commentRef} onSubmit={this.tambahKomentar} className="d-flex flex-grow-1" style={{width:this.getCommentWidth()}}>
                                                                                    <MentionsInput
                                                                                        value={tambah_komentar.komentar}
                                                                                        placeholder="Tulis Komentar..."
                                                                                        onChange={this.typeKomentar}
                                                                                        style={comment_style}
                                                                                    >
                                                                                        <Mention
                                                                                            trigger="@"
                                                                                            data={users}
                                                                                            displayTransform={(id, display) => `@${display}`}
                                                                                            markup="<@>__display__|__id__<@>"
                                                                                        />
                                                                                    </MentionsInput>
                                                                                    <button 
                                                                                        type="submit" 
                                                                                        class="btn align-self-start py-2" 
                                                                                        type="submit" 
                                                                                        disabled={tambah_komentar.komentar.trim()===""}
                                                                                    >
                                                                                        <BiSend className="fs-3"/>
                                                                                    </button>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </UnmountClosed>
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* submit & update tugas */}
                <Modal show={tambah_activity.is_open} onHide={this.hideSubmitTugas}>
                    <Modal.Header closeButton>
                        <Modal.Title className="fw-bold">
                            {!isUndefined(tugas.pengirim)&&
                                <>{tugas.pengirim.id_user===user_id()?"Update Status":"Submit Tugas"}</>
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <form onSubmit={this.submitTugas}>
                    <Modal.Body className="p-4">
                        <div className="mb-3">
                            <label for="exampleInputEmail1" class="form-label fw-semibold">Status</label>
                            <select 
                                class="form-select" 
                                name="status"
                                value={tambah_activity.status}
                                onChange={this.typeSubmitTugas}
                            >
                                <option value="progress">Sedang Dikerjakan</option>
                                <option value="problem">Ada Masalah</option>
                                <option value="delivery">Mohon Dicek</option>
                                {!isUndefined(tugas.pengirim)&&
                                    <>
                                        {tugas.pengirim.id_user===user_id()&&
                                            <>
                                                <option value="complete">Selesai</option>
                                                <option value="continue">Lanjutkan</option>
                                            </>
                                        }
                                    </>
                                }
                            </select>
                        </div>
                        {tambah_activity.status!=="complete"&&
                            <div className="mb-3">
                                <label for="exampleInputEmail1" class="form-label fw-semibold">Progress</label>
                                <div className="px-0">
                                    <RangeSlider 
                                        step={1} 
                                        value={tambah_activity.progress} 
                                        min={0} 
                                        max={100} 
                                        onChange={(e)=>this.typeProgress(e)}
                                    />
                                </div>
                            </div>
                        }
                        {tambah_activity.status==="complete"&&
                            <div className="mb-3">
                                <label for="exampleInputEmail1" class="form-label fw-semibold">Skor</label>
                                <div className="px-0">
                                    <RangeSlider 
                                        step={1} 
                                        value={tambah_activity.skor} 
                                        min={0} 
                                        max={100} 
                                        onChange={(e)=>this.typeSkor(e)}
                                        is_percent={false}
                                    />
                                </div>
                            </div>
                        }
                        <div className="mb-3">
                            <div className="d-flex justify-content-between">
                                <label for="exampleInputEmail1" class="form-label fw-semibold">
                                    {!isUndefined(tugas.pengirim)&&
                                        <>{tugas.pengirim.id_user===user_id()?"Catatan":"File Tugas"}</>
                                    }
                                </label>
                                {upload_progress.is_show&&
                                    <div>
                                        Mengupload : {upload_progress.progress}%
                                    </div>
                                }
                            </div>
                            <div>
                                <Dropzone onDrop={acceptedFiles=>this.uploadFiles(acceptedFiles)}>
                                    {({getRootProps, getInputProps}) => (
                                        <div className="form-upload d-flex flex-column rounded align-items-center p-4" {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <BiUpload className="fs-1"/>
                                            <span className="fs-8 mt-3">Drag & Drop or Browse</span>
                                        </div>
                                    )}
                                </Dropzone>

                                {tambah_activity.dokumen.length>0&&
                                    <div className="bg-gray-lt mt-3 p-3">
                                        {tambah_activity.dokumen.map(doc=>(
                                            <div className="d-flex justify-content-between mt-1 align-items-center">
                                                <a href={BASE_URL+"/"+doc.path} target="_blank" className="text-truncate d-flex align-items-center">
                                                    <DocumentIcon
                                                        data={doc.path}
                                                    />
                                                    <span className="ms-2">{doc.file_name}</span>
                                                </a>
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm ms-auto pb-1"
                                                    onClick={(e)=>this.removeFile(doc)}
                                                >
                                                    x
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between mt-0 p-4">
                        <button type="button" className="btn btn-link text-decoration-none" onClick={this.hideSubmitTugas}>
                            Batal
                        </button>
                        <button type="submit" className="btn btn-primary" onClick={this.hideTambahProyek} disabled={is_loading}>
                            {!isUndefined(tugas.pengirim)&&
                                <>{tugas.pengirim.id_user===user_id()?"Update Status":"Submit Tugas"}</>
                            }
                        </button>
                    </Modal.Footer>
                    </form>
                </Modal>

                {/* HAPUS TUGAS */}
                <Confirm
                    show={hapus_riwayat.is_open}
                    toggle={this.hideHapusRiwayatTugas}
                    title="Yakin ingin Dihapus?"
                    sub_title="Data yg dihapus tidak bisa dikembalikan lagi"
                    action={this.hapusRiwayat}
                    action_text="Hapus Data"
                    btn_type="btn-danger"
                />
            </>
        )
    }
}
DetailTugas.contextType=RouterContext

const comment_style={
    width:"100%",
    alignSelf:"center",
    control: {
        fontSize: 14,
        lineHeight: 1.2,
    },

    highlighter: {
        padding: 9,
        border: '1px solid transparent',
    },

    input: {
        fontSize: 14,
        lineHeight: 1.2,
        padding: "9px 15px",
        border: '1px solid silver',
        borderRadius:"20px"
    },

    suggestions: {
        list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 14,
            overflow:"auto",
            position:"absolute",
            bottom:14,
            width:200,
            maxHeight:300
        },

        item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',

            '&focused': {
                backgroundColor: '#cee4e5',
            },
        },
    },
}

export default withAuth(DetailTugas)