import React, { Component } from 'react'
import classNames from "classnames"
import { BsBookmarkPlus } from 'react-icons/bs'
import { FiSettings } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import withAuth from '../../../component/hoc/auth'
import Avatar from '../../../component/ui/avatar'
import { API } from '../../../config/api'
import { access_token, check_instansi, get_instansi_selected, remove_login_data } from '../../../config/config'
import RouterContext from '../../../store/router_context'

class DashboardPage extends Component {
    state={
        member:[],
        tugas:[],
        tugas_bawahan:[]
    }

    componentDidMount=()=>{
        this.getDashboardMember()
        this.getTugasDeadline()

        if(get_instansi_selected().jabatan_type.includes("atasan")){
            this.getTugasBawahan()
        }
    }
    getDashboardMember=()=>{
        API.post("/dashboard/instansi/get_dashboard_member", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi
        }))
        .then(res=>{
            this.setState({
                member:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }
    getTugasDeadline=()=>{
        API.post("/dashboard/tugas/list_tugas_deadline", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi
        }))
        .then(res=>{
            this.setState({
                tugas:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }
    getTugasBawahan=()=>{
        API.post("/dashboard/tugas/list_tugas_bawahan", JSON.stringify({
            token:access_token(),
            id_instansi:get_instansi_selected().id_instansi
        }))
        .then(res=>{
            this.setState({
                tugas_bawahan:res.data.data
            })
        })
        .catch(err=>{
            switch (err.response.status) {
                case 401:
                    remove_login_data()    
                break;
            }
        })
    }
    sortMember=()=>{
        const context=this.context

        const member=this.state.member.map(user=>{
            return Object.assign(user, {
                online:context.online_users.includes(user.id_user)?true:false
            })
        })
        
        return member
    }

    render() {
        const {member, tugas, tugas_bawahan}=this.state

        return (
            <div className="content mt-4 mb-5 mt-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="row">
                                <div className="col-md-8">
                                    {(get_instansi_selected().jabatan_type.includes("atasan")&&tugas_bawahan.length>0)&&
                                        <div class="card widget-list-tugas">
                                            <div class="card-header d-flex justify-content-between">
                                                <h5 className="card-title text-truncate">Tugas Untuk Bawahan</h5>
                                                <Link to="/tugas" className="btn btn-secondary btn-sm align-self-center text-truncate">
                                                    Lihat Semua Tugas <BsBookmarkPlus/>
                                                </Link>
                                            </div>
                                            {tugas_bawahan.map(task=>(
                                                <div class="card-body p-4 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="flex-shrink-0">
                                                            <img src="/img/task.png" className="img-task"/>
                                                        </div>
                                                        <div class="flex-grow-1 ms-4">
                                                            <div className="d-flex align-items-center text-muted fs-7 mb-2">
                                                                <FiSettings/>
                                                                <span className="ms-2">{task.nama_proyek}</span>
                                                            </div>
                                                            <div className="d-flex justify-content-between w-100">
                                                                <h2 className="fs-5 fw-bold">
                                                                    <Link to={`/tugas/detail/${task.id_tugas}`} className="link-dark">{task.nama_tugas}</Link>
                                                                </h2>
                                                            </div>
                                                            <div className="d-flex flex-column mt-2">
                                                                <span className="text-muted">Deadline</span>
                                                                <span class="badge rounded-pill bg-danger align-self-start mt-1 px-3">{task.deadline}</span>
                                                            </div>
                                                            <div className="d-flex flex-column mt-2">
                                                                <span className="text-muted fs-7">Progress</span>
                                                                <div className="w-75 mt-1">
                                                                    <div class="progress" style={{height:"4px"}}>
                                                                        <div class="progress-bar" role="progressbar" style={{width:task.progress+"%"}}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    <div class="card widget-list-tugas">
                                        <div class="card-header d-flex justify-content-between">
                                            <h5 className="card-title text-truncate">Tugas Mendekati Deadline</h5>
                                            <Link to="/tugas" className="btn btn-secondary btn-sm align-self-center text-truncate">
                                                Lihat Semua Tugas <BsBookmarkPlus/>
                                            </Link>
                                        </div>
                                        {tugas.map(task=>(
                                            <div class="card-body p-4 mb-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <img src="/img/task.png" className="img-task"/>
                                                    </div>
                                                    <div class="flex-grow-1 ms-4">
                                                        <div className="d-flex align-items-center text-muted fs-7 mb-2">
                                                            <FiSettings/>
                                                            <span className="ms-2">{task.nama_proyek}</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between w-100">
                                                            <h2 className="fs-5 fw-bold">
                                                                <Link to={`/tugas/detail/${task.id_tugas}`} className="link-dark">{task.nama_tugas}</Link>
                                                            </h2>
                                                        </div>
                                                        <div className="d-flex flex-column mt-2">
                                                            <span className="text-muted">Deadline</span>
                                                            <span class="badge rounded-pill bg-danger align-self-start mt-1 px-3">{task.deadline}</span>
                                                        </div>
                                                        <div className="d-flex flex-column mt-2">
                                                            <span className="text-muted fs-7">Progress</span>
                                                            <div className="w-75 mt-1">
                                                                <div class="progress" style={{height:"4px"}}>
                                                                    <div class="progress-bar" role="progressbar" style={{width:task.progress+"%"}}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div class="card widget-list-member">
                                        <div class="card-header d-flex justify-content-between">
                                            <h5 className="card-title">Team Member</h5>
                                            <Link to="/member" className="btn btn-secondary btn-sm align-self-center text-truncate">
                                                Lihat Semua <BsBookmarkPlus/>
                                            </Link>
                                        </div>
                                        <div class="card-body p-4 mb-4">
                                            <div class="list-group">
                                                {this.sortMember().map(user=>(
                                                    <div class="list-group-item d-flex align-items-center mb-3">
                                                        <span className="avatar avatar-xmd">
                                                            <Avatar data={user} circle/>
                                                        </span>
                                                        <div className="mx-3">
                                                            <h4 className="fs-7 fw-bold mb-0">
                                                                <a href="" className="link-dark">{user.nama_lengkap}</a>
                                                            </h4>
                                                            <span className="text-muted fs-8">{user.jabatan}</span>
                                                        </div>
                                                        <span class={classNames("translate-middle p-1 rounded-circle align-self-center ms-auto bg-secondary", {"bg-success":user.online})}>
                                                            <span class="visually-hidden"></span>
                                                        </span>
                                                    </div>
                                                ))}
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
DashboardPage.contextType=RouterContext

export default withAuth(DashboardPage)